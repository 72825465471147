<template>
    <div class="loading-overlay">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
</template>

  
<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(181, 180, 180, 0.361);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.dot {
    width: 20px;
    height: 20px;
    background-color: var(--main-color);
    border-radius: 40%;
    animation: bounce 1.7s infinite ease-in-out;
    margin: 0 10px;
}

.dot:nth-child(1) { animation-delay: -0.6s; }
.dot:nth-child(2) { animation-delay: -0.4s; }
.dot:nth-child(3) { animation-delay: -0.2s; }

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
        opacity: 0.3;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
  