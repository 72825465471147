<template>
    <loadingSpinner v-if="isLoading" />
    <b-container fluid class="cover" style="padding:0; ">
        <b-row no-gutters>
            <b-col cols="2" class="leftSide col-md-2 col-sm-1" v-if="showComponent">
                <sidebar />
            </b-col>
            <b-col class="mainContent">
                <navBar v-if="showComponent" />
                <router-view />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapState } from "vuex";
import navBar from './components/navBar.vue'; // navBar.vue 파일 경로
import sidebar from './layout/sidebar.vue'; // sidebar.vue 파일 경로
import loadingSpinner from './components/loadingSpinner.vue';
export default {
    name: 'ShipChajang',
    components: {
        navBar,
        sidebar,
        loadingSpinner
    },
    data() {
        return {
            status: null,
            showComponent: true,
        };
    },
    computed: {
      ...mapState(["isLoading"]), // Vuex의 isLoading 상태 가져오기
    },
    watch: {
        '$route' (to) {
            this.showComponent = !to.meta.hideComponent;
        }
    },
    created() {
        this.showComponent = !this.$route.meta.hideComponent;
    },
};
</script>

<style>
@import "./global.css";
.leftSide {
    border-right: 1px solid #EAEAEA;
    background-color: white;
}

.cover {
    background-color: #F2F3F7;
}

.mainContent {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 0;
}

* {
    user-select: none;
    caret-color: transparent;
}

input,
textarea,
select {
    user-select: text;
    caret-color: auto;
}
</style>
