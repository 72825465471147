<template>
    <!-- 주차장 제보 내역 중 미답변 테이블 페이지 -->
    <div>
        <h4 class="listTitle">주차장 제보 내역</h4>
    
        <div class="tip-btn">
            <button class="black-btn" @click="goBack">←</button>
            <button v-if="isPending" class="blue-btn" @click="checkAnswer">답변등록</button>
        </div>
    
        <div class="tip-content">
            <div class="tip-table-wrapper">
                <table class="tip-table">
                    <colgroup>
                        <col style="width: 20%;" />
                        <col style="width: 80%;" />
                    </colgroup>
    
                    <tbody>
                        <tr>
                            <th>주차장 주소</th>
                            <td>{{ reportData.address || '-' }}</td>
                        </tr>
                        <tr>
                            <th>주차장 이름</th>
                            <td>{{ reportData.parkingName || '-' }}</td>
                        </tr>
                        <tr>
                            <th>요금 정보</th>
                            <td>{{ reportData.formattedFee }}</td>
                        </tr>
                        <tr>
                            <th>주차장연락처</th>
                            <td>{{ reportData.contact || '-' }}</td>
                        </tr>
                        <tr>
                            <th>주차장사진</th>
                            <td>
                                <a :href="reportData.imageDir" target="_blank" :download="getFileName(reportData.imageDir)" v-if="reportData.imageDir">
                                                                이미지 저장
                                                                </a>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr class="fill-space">
                            <th>기타</th>
                            <td>{{ reportData.etc || '-' }}</td>
                        </tr>
                    </tbody>
    
                </table>
            </div>
    
            <!-- 답변 div -->
            <div class="tip-answer">
                <div class="textareaFixed">
                    <!-- 등록 승인 라디오 버튼 -->
                    <!-- 라디오 버튼 목록 -->
                    <label v-for="option in statusOptions" :key="option.value" :class="getLabelClass(option.value)">
                          <input 
                            type="radio" 
                            v-model="status" 
                            :value="option.value"
                            @click="preventSelection" />
                          {{ option.label }}
                        </label>
                </div>
    
                <div class="textareaContainer ">
                    <textarea class="content-input" v-model="answer" :disabled="!isPending" :placeholder="reportData.answer"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();
const route = useRoute();


const goBack = () => {
    router.back(); // 브라우저의 뒤로 가기 기능 실행
};

const reportData = computed(() => store.getters['report/getReportById'](route.params.id));
const isPending = ref(reportData.value.status === 'PENDING');

const statusOptions = [
    { value: "resolve", label: "제보 승인" },
    { value: "reject", label: "제보 거절" }
];

const getLabelClass = (value) => {
    return {
        "resolve": "text-blue", // 초록색
        "reject": "text-red" // 빨간색
    }[value] || "text-black"; // 기본값 (검정색)
};

const statusRef = ref("waiting"); // 라디오 버튼 선택을 위한 상태

// `isPending`이 false일 때 readonly 상태로 변경
const status = computed({
    get: () => (isPending.value ? statusRef.value : reportData.value.formattedStatus),
    set: (newValue) => {
        if (isPending.value) {
            statusRef.value = newValue;
        }
    }
});

const answer = ref("")

const getFileName = (url) => {
    if (!url) return "parking-report-image.jpg"; // URL이 없을 경우 기본 파일 이름
    const fileName = url.split("/").pop(); // URL에서 마지막 경로 추출
    if (!fileName.includes(".")) {
        // 확장자가 없을 경우 기본 확장자 추가
        return "parking-report-image.jpg";
    }
    return "parking-report-image.jpg";
};

const checkAnswer = () => {
    if (!status.value) {
        Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
            text: "제보 내역에 대한 답변 여부를 선택해주세요.",
            confirmButtonText: '확인',
            confirmButtonColor: '#5B67EC',
            imageWidth: 55,
            imageHeight: 55,
        });
        return;
    }

    if (!answer.value.trim()) {
        Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
            text: "제보 내역에 대한 답변을 작성해주세요.",
            confirmButtonText: '확인',
            confirmButtonColor: '#5B67EC',
            imageWidth: 55,
            imageHeight: 55,
        });
        return;
    }

    postAnswer();

};

const postAnswer = () => {
    try {
        store.dispatch('report/postReportAnswer', { id: reportData.value.id, answer: answer.value.trim(), status: status.value });
    } catch (error) {
        console.error('답변 저장 실패:', error.response ? error.response.data : error.message);
    } finally {
      goBack();
    }
};

const preventSelection = (event) => {
    if (!isPending.value) {
        event.preventDefault();
    }
};
</script>

<!--<script>
import Swal from 'sweetalert2';

export default {
    name: "tipDetail00",
    props: ['id'],
    data() {
        return {
            모달창열렸니: false,
            등록모달창열렸니: false,
            noticeData: null,
            // approveChecked: false,  // 등록 승인 체크 여부
            // rejectChecked: false,   // 등록 거절 체크 여부
            status: '',
            initialAnswer: '',
        };
    },
    computed: {
        selectedButton() {
            return this.$store.state.selectedButton;
        }
    },
    created() {
        if (this.$route.query.itemData) {
            this.noticeData = JSON.parse(this.$route.query.itemData);
            this.initialAnswer = this.noticeData.answer || ''; // 초기 답변 설정 
            console.log("Loaded noticeData:", this.noticeData);
        } else {
            this.$router.push("/parkinglotTip");
        }
    },
    methods: {
        goToLotTipPage() {
            this.$router.push("/parkinglotTip")
        },
        getFileName(url) {
            if (!url) return "parking-report-image.jpg"; // URL이 없을 경우 기본 파일 이름
            const fileName = url.split("/").pop(); // URL에서 마지막 경로 추출
            if (!fileName.includes(".")) {
                // 확장자가 없을 경우 기본 확장자 추가
                return "parking-report-image.jpg";
            }
            return "parking-report-image.jpg";
        },
        async submitAnswer() {
            if (!this.status) {
                Swal.fire({
                    imageUrl: require('@/assets/icons/Vector.svg'),
                    html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                    <div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>
                      답변을 등록하시려면, <span style='color:#F93C65;'>등록 승인 여부</span>를 반드시 선택해 주세요</div>`,
                    confirmButtonText: '확인',
                    confirmButtonColor: '#5B67EC',
                    imageWidth: 55,
                    imageHeight: 55,
                });
                return;
            }

            if (!this.noticeData.answer || this.noticeData.answer.trim() === '' || this.noticeData.answer.trim() === this.initialAnswer.trim()) {
                Swal.fire({
                    imageUrl: require('@/assets/icons/Vector.svg'),
                    html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                    <div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>
                      답변을 등록하시려면, <span style='color:#F93C65;'>답변내용</span>을 반드시 입력해 주세요</div>`,
                    confirmButtonText: '확인',
                    confirmButtonColor: '#5B67EC',
                    imageWidth: 55,
                    imageHeight: 55,
                });
                return;
            }

            try {

                if (!this.$store.state.selectedButton) {
                    console.log('Vuex selectedButton이 비어 있음. 기본값 설정 중...');
                    this.$store.commit('setSelectedButton', this.buttons[0]); // 기본값으로 설정
                }

                const selectedButton = this.$store.state.selectedButton;
                console.log('selectedButton:', selectedButton);

                if (!selectedButton.apiEndpoint) {
                    throw new Error('API 엔드포인트가 설정되지 않았습니다.');
                }


                await this.$store.dispatch('submitAnswer', {
                    // 선택된 버튼 전달
                    answer: this.noticeData.answer, // 입력된 답변 전달
                    status: this.status, // 라디오 버튼으로 설정된 상태 전달
                    id: this.noticeData.id
                });
                this.$router.push('/parkinglotTip');
                Swal.fire({
                    imageUrl: require('@/assets/icons/Vector.svg'),
                    html: `<div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>답변이 성공적으로 등록되었습니다</div>`,
                    confirmButtonText: '확인',
                    confirmButtonColor: '#5B67EC',
                    imageWidth: 55,
                    imageHeight: 55,
                });

            } catch (error) {
                console.error('오류 발생:', error.message);
            }
        }
    }
}
</script>-->

<style scoped>
.tip-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* 두 영역이 동일한 너비를 가지도록 설정 */
    gap: 1.25rem;
    width: 100%;
    margin-left: 6.25rem;
    padding-right: 150px;
    margin-top: 1.25rem;
    box-sizing: border-box;
    height: 70vh;
}

.tip-btn {
    display: flex;
    gap: 0.625rem;
    width: 100%;
    margin-left: 6.25rem;
    padding-right: 6.25rem;
    margin-top: 40px;
}

.tip-table-wrapper,
.tip-answer {
    border-radius: 1rem;
    width: 100%;
    overflow: hidden;
}

.tip-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 1.125rem;
    overflow: hidden;
    min-height: 100%;
    background-color: white;
    border: 1px solid #6c757d;
}

.tip-table th,
.tip-table td {
    padding-right: 0.625rem;
    padding-left: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border: 1px solid #ddd;
    text-align: left;
}

.tip-table tbody tr.fill-space td {
    height: 100%;
    vertical-align: top;
}

.tip-table tbody tr:last-of-type td {
    border-bottom: none;
}

.tip-table tbody tr:last-of-type th {
    border-bottom: none;
}

.content-input:disabled {
    background-color: white;
}

.textareaFixed {
    display: flex;
    gap: 0.625rem;
    height: 5%;
    align-items: center;
}

.textareaContainer {
    height: 95%;
}

.content-input {
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 1rem;
    border: white;
    resize: none;
}

textarea::placeholder {
    white-space: pre-line;
}

input[type="radio"] {
    accent-color: #5b67ec;
}

.text-blue {
    color: #5b67ec;
}

.text-red {
    color: #f93c65;
}
</style>