// src/store/index.js
import { createStore } from 'vuex';
import auth from './modules/auth'; // 인증 관련 Vuex 모듈
import parkingLot from './modules/parkingLot';
import dashboard from './modules/dashboard';
import notice from './modules/notice';
import inquiry from './modules/inquiry';
import report from './modules/report';
import parkingProcess from './modules/parkingProcess';
import parkingOwner from './modules/parkingOwner';
import tempParking from './modules/tempParking';
import sales from './modules/sales';

import { basicInstance } from '@/api/index'; // ✅ API 인스턴스 불러오기
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  modules: {
    auth,
    dashboard,
    parkingLot,
    tempParking,
    notice,
    inquiry,
    report,
    parkingProcess,
    parkingOwner,
    sales
  }, 
  
  state: {
    api: basicInstance, // ✅ Store에서 API 인스턴스를 직접 관리
    isLoading: false,
  },

  mutations: {
    SHOW_LOADING(state) {
      state.isLoading = true;
    },
    HIDE_LOADING(state) {
      state.isLoading = false;
    }
  },
  actions: {
    showLoading({ commit }) {
      commit("SHOW_LOADING");
    },
    hideLoading({ commit }) {
      commit("HIDE_LOADING");
    },
  },

  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    })
  ]
  
});
