import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatPhoneNumber } from '@/utils/stringFormatter';

export default {
    namespaced: true,
    state: {
        ownerList: [],
    },

    getters: {
        ownerList: (state) => state.ownerList.map(item => ({
            ...item,
            phoneNumber: formatPhoneNumber(item.phoneNumber),
            id: item.ownerId,
            affCnt: item.parkingInfoList.length,
            affList: item.parkingInfoList.map(item => item.name).join(", "),
            affSelection: item.parkingInfoList.map(item => {item.name}),
        })),
        getOwnerById: (state) => (id) => {
            const item = state.ownerList.find(n => n.ownerId == id);
            return item ? {
                ...item,
                id: item.ownerId,
                affCnt: item.parkingInfoList.length,
                affSelection: item.parkingInfoList,
            } : null; // 찾지 못하면 null 반환
        },
        getAffParkingLotByName: (state) => (name) => {
            const item = state.ownerList.parkingInfoList.find(n => n.name == name);
            return item ? {
                ...item,
            } : null; // 찾지 못하면 null 반환
        },
    },

    mutations: {
        SET_OWNER_LIST(state, data) {
            state.ownerList = data;
        },
    },

    actions: {
        // 소유자 리스트 조회
        async getOwnerList({ commit }) {
            try {
                const response = await basicInstance.get('/parking-owner');
                commit('SET_OWNER_LIST', response.data.list);
            } catch (error) {
                console.error('소유자 조회 실패:', error);
                throw error;
            }
        },

        // 소유자 리스트 등록
        async addParkingOwner({ dispatch }, formData) {
            const form = new FormData();
            form.append('registerParkingOwnerInfo', formData.get('file')); // formData에 이미 파일이 있는 경우

            try {
                // axios 요청에서 Content-Type은 자동으로 설정되므로 명시적으로 넣지 않음
                await basicInstance.post('/parking-owner', form, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                dispatch('getOwnerList'); // 서버에 저장된 후 주차장 리스트 다시 가져오기
            } catch (error) {
                console.error('Error saving items to the server:', error);
                throw error;
            }
        },

        // 소유자 다중 삭제
        async deleteMultipleOwners({ dispatch }, data) {
            console.log("삭제하려는 주차장:", data);
            try {
                await basicInstance.delete(`/parking-owner/selected`, {
                    data: {
                        idList: data,
                    },
                });
                await dispatch('getOwnerList');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error;
            }
        },
    }
};
