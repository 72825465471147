import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatFullDateTime, formatPrice } from '@/utils/stringFormatter';

export default {
    namespaced: true,
    state: {
        parkingPair: [],
        parkingSales: []
    },

    getters: {
        ParkingPair: (state) => state.parkingPair,
        ParkingSales: (state) => state.parkingSales,
        ParkingSaleList: (state) => (state.parkingSales.salesInfoList ?? []).sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime)).map(item => ({
            ...item,
            formattedStatus: {
                PAYMENT_DONE: "결제 완료",
                CANCELED: "전체 취소",
                PARTIAL_CANCELED: "부분 취소",
            }[item.status] || "알 수 없음",
            formattedPaymentDate: formatFullDateTime(item.paymentDateTime),
            parkingPrice: formatPrice(item.parkingPrice),
            pgVat: formatPrice(item.pgVat),
            parkingOwnerCharge: formatPrice(item.parkingOwnerCharge),
            paymentPrice: formatPrice(item.paymentPrice),
            cancelPrice: formatPrice(item.cancelPrice),
            netProfit: calculateProfit(item),
        })),
    },

    mutations: {
        SET_PARKING_PAIR(state, data) {
            state.parkingPair = data;
            console.log(data)
        },
        SET_PARKING_SALES(state, data) {
            data.netParkingAmount = formatPrice(data.totalAmount - data.cancelAmount);
            data.totalAmount = formatPrice(data.totalAmount);
            data.cancelAmount = formatPrice(data.cancelAmount);
            data.pgVat = formatPrice(data.pgVat);
            data.parkingOwnerVat = formatPrice(data.parkingOwnerVat);
            data.totalProfit = formatPrice(data.totalProfit);
            state.parkingSales = data
        },

    },

    actions: {
        async getParkingPair({ commit }) {
            try {
                const response = await basicInstance.get('/parking/pair', {
                    params: {
                        "filterBy": 'AFFILIATED'
                    }
                });
                commit('SET_PARKING_PAIR', response.data.list);
            } catch (error) {
                console.error('getParkingLots 실패:', error);
                throw error;
            }
        },

        async getParkingSalesById({ commit }, { parkingId, startDate, endDate } = {}) {
            try {
                const response = await basicInstance.get('/parking/sales', {
                    params: {
                        parkingId,
                        startDate,
                        endDate
                    },
                });
                commit('SET_PARKING_SALES', response.data);
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error;
            }

        },
    },
}


export const calculateProfit = (item) => {
    if (item.paymentPrice === 0) {
        return 0
    }
    const profit = item.paymentPrice - item.pgVat - item.parkingOwnerCharge
    return new Intl.NumberFormat('ko-KR').format(profit)
  }