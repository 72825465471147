import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatFullDateTime } from '@/utils/stringFormatter';

export default {
    namespaced: true,
    state: {
        inquiryList: [],
    },

    getters: {
        inquiryList: (state) => state.inquiryList.map(item => ({
            ...item,
            formattedStatus: {
                COMPLETE: "답변 완료",
                WAITING: "답변 대기"
            }[item.status] || "알 수 없음",
            formattedCreatedDate: formatFullDateTime(item.createdDate),
            formattedUpdateDate: {
                COMPLETE: formatFullDateTime(item.updatedDate),
                WAITING: "-"
            }[item.status] || '-'
        })),
        getInquiryById: (state) => (id) => {
            const item = state.inquiryList.find(n => n.id == id);

            return {
                ...item,
                formattedStatus: {
                    COMPLETE: "답변 완료",
                    WAITING: "답변 대기"
                }[item.status] || "알 수 없음",
                formattedCreatedDate: formatFullDateTime(item.createdDate),
                formattedUpdateDate: {
                    COMPLETE: formatFullDateTime(item.updatedDate),
                    WAITING: "-"
                }[item.status] || '-'
            }
        }
    },

    mutations: {
        SET_INQUIRY_LIST(state, data) {
            state.inquiryList = data;
        },
    },

    actions: {
        //문의내역 리스트 조회
        async getInquiryList({ commit }, { startDate, endDate } = {}) {
            try {
                const response = await basicInstance.get('/inquiries/admin', {
                    params: {
                        startDate,
                        endDate
                    },
            });
                commit('SET_INQUIRY_LIST', response.data.list);
            } catch (error) {
                console.error('문의내역 조회 실패:', error);
                throw error;
            }
        },

        // 문의내역 답변 등록
        async postInquiryAnswer({ dispatch }, { id, answer }) {
            console.log("문의내역 답변:", answer);
            try {
                await basicInstance.post(`/inquiries/${id}/answer`, { answer });
                await dispatch('getInquiryList');
            } catch (error) {
                console.error('문의내역 작성 에러:', error.response ? error.response.data : error.message);
            }
        },

        // 문의내역 다중 삭제
        async deleteMultipleInquiries({ dispatch }, id) {
            console.log("삭제하려는 다중 공지 :", id);
            try {
                await basicInstance.delete(`/inquiries/selected`, {
                    data: {
                        idList: id,
                    },
                });
                await dispatch('getInquiryList');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error; // 오류 발생 시 호출자에게 에러를 던짐
            }
        },

        // 문의내역 답변 수정
        async updateInquiryAnswer({ dispatch }, { id, answer }) {
            console.log("문의내역 답변:", answer);
            try {
                await basicInstance.patch(`/inquiries/${id}/answer`, { answer });
                await dispatch('getInquiryList');
            } catch (error) {
                console.error('문의내역 작성 에러:', error.response ? error.response.data : error.message);
            }
        },


    }
};
