<template>
    <b-modal v-model="isOpen" hide-footer hide-header centered>
        <div class="modalContents">
            <div class="modalImageWrapper">
                <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
            </div>
            <div class="modalTextWrapper">
                <p class="modalTitle">{{ modalTitle }}</p>
            </div>
            <div class="modalBtn">
                <b-button class="modal-blue-btn" @click="confirmAction">네</b-button>
                <b-button class="modal-black-btn" @click="isOpen = false">아니오</b-button>
            </div>
        </div>
    </b-modal>
</template>


<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  modelValue: Boolean, // v-model 바인딩
  modalTitle: {
    type: String,
    default: "해당 작업을 진행하시겠습니까?"
  },
  onConfirm: Function // 부모에서 삭제 함수 전달
});

const emit = defineEmits(["update:modelValue"]); // ✅ 명시적으로 이벤트 정의

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const confirmAction = () => {
  if (props.onConfirm) props.onConfirm(); // 부모에서 전달한 함수 실행
  isOpen.value = false; // 모달 닫기
};
</script>