// src/store/auth.js
import { basicInstance } from '@/api/index'; // Axios 인스턴스

export default {
    namespaced: true,
    state: {
        accessToken: null,
        refreshToken: null,
        userProfile: [],
    },

    getters: {
        getAccessToken: (state) => state.accessToken,
        isAuthenticated: (state) => !!state.accessToken, // 로그인 상태 확인
        getUserProfile: (state) => state.userProfile
    },

    mutations: {
        SET_ACCESS_TOKEN(state, token) {
            state.accessToken = token;
        },
        SET_REFRESH_TOKEN(state, token) {
            state.refreshToken = token;
        },
        CLEAR_TOKENS(state) {
            state.accessToken = null;
            state.refreshToken = null;
        },
        SET_USER_PROFILE(state, profile) {
            state.userProfile = profile;
        },
    },

    actions: {
        async login({ dispatch, commit }, credentials) {
            try {
                const response = await basicInstance.post('/auth/admin', credentials);
                commit('SET_ACCESS_TOKEN', response.data.accessToken);
                commit('SET_REFRESH_TOKEN', response.data.refreshToken);

                dispatch('getProfile')
            } catch (error) {
                console.error('로그인 실패:', error);
                throw error;
            } 
        },

        async logout({ commit }) {
            try {
                await basicInstance.delete('/auth');
                commit('CLEAR_TOKENS');
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },

        async refreshToken({ commit, state }) {
            try {
                const response = await basicInstance.put('/auth/reissue', {}, {
                    headers: {
                    "refresh-token": `Bearer ${state.refreshToken}`,
                    }
                });
                commit('SET_ACCESS_TOKEN', response.data.accessToken);
                commit('SET_REFRESH_TOKEN', response.data.refreshToken);
                return response.data.accessToken;
            } catch (error) {
                console.error('토큰 갱신 실패:', error);
                throw error;
            }
        },

        async getProfile({ commit }) {
            try {
                const response = await basicInstance.get('/users/profile');
                console.log(response.data);
                commit('SET_USER_PROFILE', response.data);
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },
    }
};
