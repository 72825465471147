<template>
  <div>
    
  </div>
</template>
<!-- <template>
  <div>
    <h4 class="listTitle">주차장 기기 리스트</h4>

    <div class="divLarge">
      <div class="div1">
        <select v-model="selectedParking" class="custom-select" style="width: 11.25rem; margin-right: 10px;">
          <option disabled value="">주차장 이름</option>
          <option v-for="device in uniqueParkingNames" :key="device" :value="device">
            {{ device }}
          </option>
        </select>

        <div class="search-wrapper">
          <input v-model="search" @input="emitFilterChange" class="search-input" type="text" placeholder="검색어를 입력하세요" />
          <button @click="emitFilterChange" type="submit" class="search-button">
            <img src="../assets/icons/SearchIcon.svg" alt="Search">
          </button>
        </div>
        
      </div>

      <div class="div2">
        <div class="divsmall">
          <button class="blue-btn" @click="downloadExcel">엑셀다운로드</button>
          <button class="red-btn" @click="deleteCheckDeviceModal = true">삭제</button>
        </div>
        <div class="divsmall2">
          <button @click="handleClickedButton('WORKING')"
            :class="{ 'selected': clickedButton === 'WORKING' }">주차중</button>
          <button @click="handleClickedButton('WAITING')"
            :class="{ 'selected': clickedButton === 'WAITING' }">대기중</button>
          <button @click="handleClickedButton('RESERVED')"
            :class="{ 'selected': clickedButton === 'RESERVED' }">예약완료</button>
          <button @click="handleClickedButton('UNAVAILABLE')"
            :class="{ 'selected': clickedButton === 'UNAVAILABLE' }">이용불가</button>
        </div>
      </div>
    </div>

    <basicTable  :original-items="originalItems" :items="filteredItems" :columns="columns" :fields="fields" @rowClick="handleRowClick"
      v-model="selectedItems" @update-selected-items="setSelectedData" :keyField="'macaddress'">
      <template #checkbox="{ item }">
        <input type="checkbox" :value="item" v-model="selectedItems" @update-selected-items="setSelectedData" />
      </template>
    </basicTable>

    // 삭제모달
    <b-modal v-model="deleteCheckDeviceModal" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">주차장 기기 리스트를 삭제하시겠습니까?</p>
          <p>삭제된 기기 리스트는 복구되지 않습니다</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn"  @click="deleteSelectedDevices">네</b-button>
          <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import basicTable from "../components/table.vue";
import { mapActions, mapState } from "vuex";
import * as XLSX from 'xlsx';

export default {
  components: {
    basicTable,
  },
  data() {
    return {
      type: 'type4',
      deleteCheckDeviceModal: false,
      selectedItems: [],
      clickedButton: null,  // 필터 버튼 선택 상태
      search: '',  // 검색어
      selectedParking: '',  // 선택된 주차장 이름
      filteredItems: [],  // 필터링된 아이템
      columns: [
        { title: '주차장 이름', width: '15%' },
        { title: 'MAC Address', width: '20%' },
        { title: '기기 ID', width: '13%' },
        { title: '설치 위치', width: '8%' },
        { title: '시작 시간', width: '8%' },
        { title: '종료 시간', width: '8%' },
        { title: '배터리 잔량', width: '15%' },  // 배터리 잔량 열의 너비 설정
        { title: '기기 상태', width: '13%' },
      ],
      fields: [
        "parkingName",
        "macaddress",
        "deviceName",
        "installPosition",
        "availableStartTime",
        "availableEndTime",
        "batteryLevel",
        "status",
      ],
      originalItems: [],  // 초기 데이터 저장용
    };
  },
  computed: {
    ...mapState(["deviceList", "parkingList"]),  // Vuex의 상태에서 기기 리스트와 주차장 리스트를 가져옴
    // 중복되지 않은 주차장 이름 목록 반환

  },
  methods: {

    ...mapActions(["getDeviceList","deleteCheckDevice"]),  // Vuex 액션 호출
// 기기 리스트 조회
    async fetchDeviceList() {
      try {
        await this.getDeviceList();  // 주차장 ID 없이 기기 리스트 가져오기
        this.originalItems = [...this.deviceList];
        this.originalItems.sort((a, b) => a.parkingName.localeCompare(b.parkingName));
        this.applyFilters();  // 필터 적용
      } catch (error) {
        console.error("기기 리스트를 불러오는 중 오류가 발생했습니다.", error);
      }
    },
// 버튼 필터 섹션 기능    
    handleClickedButton(filterBy) {
      this.clickedButton = this.clickedButton === filterBy ? null : filterBy;
      this.applyFilters();
    },
    emitFilterChange() {
      this.applyFilters(); // 버튼 클릭 시 필터링 적용
    },
    applyFilters() {
    // 시간을 시:분 형식으로 포맷팅하는 함수
    const formatTime = time => time ? time.slice(0, 5) : ''; 
    
this.filteredItems = [...this.originalItems]
      .map(item => {
        // 설치 위치를 알파벳-숫자 형식으로 변환
        const alphabet = String.fromCharCode(65 + item.installX);
        const installPosition = `${alphabet}-${item.installY}`;

        // 필요한 데이터를 가공하여 새로운 객체를 반환
        return {
            ...item,
            availableStartTime: formatTime(item.availableStartTime),
            availableEndTime: formatTime(item.availableEndTime),
            installPosition: installPosition, // 변환된 설치 위치를 적용
        };
    }).filter(item => {
        const statusMatch = this.clickedButton ? item.status === this.clickedButton : true;
        const searchMatch = this.search ? item.parkingName?.includes(this.search) : true;
        const parkingMatch = this.selectedParking ? item.parkingName === this.selectedParking : true;

        return statusMatch && searchMatch && parkingMatch;
    })

},

// 테이블  행 클릭시 기능
    handleRowClick(item, index) {
      // 행 클릭하면 데이터가 clickData에 담김   
      this.clickedData = item;
      this.selectedRowIndex = index;
      this.activePanel = 'clickedInfo4';
      this.isDetailsVisible = true;
      
      console.log("데이터띄우기", this.clickedData.name);
      this.selectedParking = item.name
    },

// 체크박스 선택시 filteredList에 담김
setSelectedData(filteredList) {
      console.log("setSelectedData 함수 호출됨");  // 이 로그가 출력되는지 확인
      this.selectedItems = filteredList;
      console.log("체크박스 데이터:", filteredList);
    },
// 필터섹션 내 삭제 버튼 
    deleteSelectedDevices() {
    if (this.selectedItems.length === 0) {
      alert('삭제할 기기를 선택해주세요.');
      return;
    }

    const deviceUIDList = this.selectedItems.map(item => item.deviceUID);
    
    this.deleteCheckDevice(deviceUIDList)
      .then(() => {
        console.log("삭제 요청 완료:", deviceUIDList);
        this.deleteCheckDeviceModal = false; // 모달 닫기
        this.fetchDeviceList(); // 목록 새로 고침
      })
      .catch(error => {
        console.error('기기 삭제 중 오류 발생:', error);
      });
  },
// 엑셀다운로드 버튼 클릭시  
    downloadExcel() {
      // 테이블 데이터를 배열 형태로 준비
      const dataToExport = this.filteredItems.map(item => ({
        '주차장 이름': item.parkingName,
        'MAC 주소': item.macaddress,
        '기기 이름': item.deviceName,
        '설치 위치' : `${ String.fromCharCode(65 + item.installY)}-${item.installX}`,
        '사용 가능 시작 시간': item.availableStartTime,
        '사용 가능 종료 시간': item.availableEndTime,
        '배터리 잔량': item.batteryLevel,
        '기기 상태': item.status, // 상태 값을 변환하는 함수 사용
      }));

      // 워크북과 시트 생성
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();

      // 시트를 워크북에 추가
      XLSX.utils.book_append_sheet(workbook, worksheet, "주차장 기기 리스트");

      // 파일을 엑셀 형식으로 다운로드
      XLSX.writeFile(workbook, "주차장 기기 리스트.xlsx");
    },
  
  },
  mounted() {
    this.originalItems = [...this.deviceList]
    this.fetchDeviceList();  
  },
  watch: {
    deviceList() {
      this.applyFilters(); 
    },
    selectedParking() {
      this.applyFilters(); 
    }
  }
};
</script>

<style scoped>
.divLarge {
  margin-left: 6.25rem;
  margin-top: 1.25rem;
}
</style> -->
