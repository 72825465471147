<template>
    <div>
        <h4 class="listTitle">공지사항</h4>
    
        <div class="table-wrapper">
            <div class="header-wrapper">
                <div class="btn-wrapper ">
                    <router-link to="/NoticeWrite">
                        <button class="blue-btn">글쓰기</button>
                    </router-link>
                    <button class="red-btn" @click="confirmDelete">삭제</button>
                </div>
            </div>
    
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="true" v-model:selectedRows="selectedNotices" @row-clicked="goToDetail" />
    
            <div v-if="noticeList.length > 0">
            <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="noticeList.length" align="center" aria-controls="my-table"></b-pagination>
            </div>
        </div>
    
        <!-- 삭제 확인 모달 -->
        <b-modal v-model="isModalOpen" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                </div>
                <div class="modalTextWrapper">
                    <p class="modalTitle">공지사항을 삭제하시겠습니까?</p>
                    <p class="modalText">삭제된 공지사항은 복구되지 않습니다</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="deleteItem">네</b-button>
                    <b-button class="modal-black-btn" @click="isModalOpen = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import Swal from 'sweetalert2';


const router = useRouter();
const store = useStore();

// ✅ 반응형 변수 선언
const isModalOpen = ref(false);
const currentPage = ref(1);
const perPage = ref(15);

const tableHeaders = ref([
    { key: 'title', title: '제목' },
    { key: 'writerName', title: '작성자', width: '6rem' },
    { key: 'isFixed', title: '상단고정', width: '8rem' },
    { key: 'createdDate', title: '작성날짜', width: '10rem' },
]);

const selectedNotices = ref([]);

// 공지사항 리스트
const noticeList = computed(() => store.getters['notice/noticeList']);

// 공지사항 정렬 및 페이징 처리
const paginatedItems = computed(() => {
    if (!Array.isArray(noticeList.value) || noticeList.value.length === 0) {
        return [];
    }
    const fixedItems = noticeList.value.filter(item => item.isFixed);
    const normalItems = noticeList.value.filter(item => !item.isFixed);
    const sortedItems = [...fixedItems, ...normalItems];

    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return sortedItems.slice(start, end);
});

const goToDetail = (row) => {
    // 상세 페이지로 이동 (라우터에 데이터 전달)
    console.log(row.id, "를 클릭했어요")
    router.push({ name: 'noticeDetail', params: { id: row.id } });
};

// 🔔 공지사항 목록 불러오기
const getNoticeList = async () => {
    try {
        await store.dispatch('notice/getNoticeList');
    } catch (error) {
        console.error('공지사항 목록 불러오기 오류:', error);
    }
};

// 🔔 삭제 모달 창 확인
const confirmDelete = () => {
    if (selectedNotices.value.length === 0) {

        Swal.fire({
            text: "삭제할 항목을 선택하세요.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",

        });

        return;
    }
    isModalOpen.value = true;
};

// 🔔 선택된 항목 삭제
const deleteItem = async () => {
    if (selectedNotices.value.length > 0) {
        try {
            await store.dispatch('notice/deleteMultipleNotices', selectedNotices.value);
            isModalOpen.value = false; // 모달 닫기
            selectedNotices.value = []; // 선택 항목 초기화
            await getNoticeList(); // 목록 갱신
        } catch (error) {
            console.error('삭제 중 오류 발생:', error);
        }
    } else {
        alert('삭제할 항목이 선택되지 않았습니다.');
    }
};

// 마운트되면 공지사항 목록 불러오기
onMounted(() => {
    getNoticeList();
});
</script>
