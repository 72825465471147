<template>
    <div>
        <h4 class="listTitle">한시적 주차 허용 리스트</h4>
        <div class="table-wrapper">
            <div class="header-wrapper ">
                <div class="left-header">
                    <div class="btn-wrapper mt-2">
                        <input type="file" ref="fileInput" @change="handleFileUpload" accept=".xls,.xlsx" hidden />
                        <button class="blue-btn" @click="triggerFileInput">주차장 등록</button>
                        <button class="blue-btn" @click="downloadTemplate">양식 다운로드</button>
                        <button class="red-btn" @click="confirmDelete">삭제</button>
                    </div>
                </div>
                <div class="right-header">
                    <div class="search-wrapper">
                        <b-dropdown :text="searchKeyword.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item) in keywordOptions" :key="item" @click="searchKeyword = item" class="custom-dropdown">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <div class="search-layout">
                            <input v-model="search" @input="handleSearchInput" type="text" placeholder="검색어를 입력하세요" />
                            <i class="bi bi-search"></i>
                        </div>
                    </div>
                    <div class="btn-wrapper mt-2">
                        <b-dropdown :text="selectedRegion.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item) in regionOptions" :key="item" @click="changeRegion(item)">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
    
                        <b-dropdown :text="selectedCity.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item, index) in filteredCityOptions" :key="index" @click="selectedCity = item">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <!-- searchFilter 컴포넌트 -->
                <!-- <searchFilter :templateFileName="'한시적 주차 허용 등록 양식.xlsx'" :templateFilePath="'/한시적 주차구역 등록 양식_20250226.xlsx'" :regionOptions="regionOptions" 
                                :cityOptions="cityOptions" :isParkingList="false" @filter-change="handleFilterChange" @delete-items="deleteSelectedItems"
                                    :route="$route" :type="'type2'" /> -->
            </div>
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="true" v-model:selectedRows="selectedTempParking" @row-clicked="openDetail" />
    
            <div v-if="paginatedItems.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="filteredList.length" align="center" aria-controls="my-table"></b-pagination>
            </div>
        </div>
    
        <div class="overlay" v-if="selectedRow">
            <div class="backDrop" @click="selectedRow = null" />
            <div class="sidebar-wrapper">
                <button class="close-btn" @click="selectedRow = null">X</button>
    
                <div class="mt-5">
                    <div class="form-group">
                        <label>지역</label>
                        <input type="text" v-model="selectedRow.address" disabled=true />
                    </div>
                    <div class="form-group">
                        <label>허용 시작 주소</label>
                        <input type="text" v-model="selectedRow.startAddress" disabled=true />
                    </div>
                    <div class="form-group">
                        <label>허용 끝 주소</label>
                        <input type="text" v-model="selectedRow.endAddress" disabled=true />
                    </div>
                    <div class="form-group">
                        <label>구간</label>
                        <input type="text" v-model="selectedRow.description" disabled=true />
                    </div>
                    <div class="form-group">
                        <label>주차 허용 시간</label>
                        <div class="fee-layout">
                            <input type="time" v-model="selectedRow.startTime" disabled=true />
                            <p>~</p>
                            <input type="time" v-model="selectedRow.endTime" disabled=true />
                        </div>
                    </div>
    
                    <div class="mt-5">
                        <button class="modal-red-btn" @click="openModal('해당 데이터를 삭제하시겠습니까?', deleteTempParking)">삭제</button>
                    </div>
                </div>
            </div>
        </div>
        <confirmModal v-model="isModalOpen" :modalTitle="modalTitle" :onConfirm="onConfirm" />
        <oneBtnModal v-model="isOneBtnModalOpen" :modalTitle="modalTitle" :modalText="modalText" :alert="isAlertModal" />
    
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect, reactive } from 'vue';
import confirmModal from '@/components/confirmModal.vue';
import oneBtnModal from '@/components/oneBtnModal.vue';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';

onMounted(() => {
    store.dispatch('tempParking/getTempParkingList');
});

const store = useStore();

const currentPage = ref(1);
const perPage = ref(15);
const selectedRow = ref(null);
const tableHeaders = reactive([
    { key: 'region', title: '지역', width: '4rem' },
    { key: 'city', title: '시군구', width: '3rem' },
    { key: 'startAddress', title: '허용 시작 주소', width: '8rem' },
    { key: 'endAddress', title: '허용 끝 주소', width: '8rem' },
    { key: 'description', title: '구간', width: '5rem' },
    { key: 'startTime', title: '허용 시작 시간', width: '5rem' },
    { key: 'endTime', title: '허용 종료 시간', width: '5rem' },
]);

const selectedRegion = ref({ key: null, label: "전국" });
const selectedCity = ref({ key: null, label: "시군구" });
const regionOptions = computed(() => store.getters['tempParking/regionOptions']);
const cityOptions = computed(() => store.getters['tempParking/cityOptions']);
const filteredCityOptions = ref([])
const changeRegion = (item) => {
    console.log(item)
    
    if (selectedRegion.value != item) {
        selectedCity.value = { key: null, label: "시군구" }
    }
    selectedRegion.value = item
}

const keywordOptions = [
    { key: 'address', label: '주차장 주소' },
    { key: 'name', label: '주차장 구간' }
];
const searchKeyword = ref({ key: 'address', label: '주차장 주소' }, );
const search = ref('');

const handleSearchInput = (event) => {
    // 검색어가 한 글자여도 필터가 작동하도록 메서드 호출
    search.value = event.target.value;
};

const tempParkingList = computed(() => store.getters['tempParking/formattedTempParkingList']);
const selectedTempParking = ref([]);
const filteredList = ref([]);

watchEffect(() => {
    if (tempParkingList.value.length === 0) {
        filteredList.value = [];
        return;
    }

    if (selectedRegion.value.key) {
        filteredCityOptions.value = cityOptions.value[selectedRegion.value.label]
    } else {
        filteredCityOptions.value = [{ key: null, label: "시군구" }]
    }

    filteredList.value = tempParkingList.value.filter(item => {
        const regionMatch = !selectedRegion.value.key || item.region === selectedRegion.value.key;
        const cityMatch = !selectedCity.value.key || item.city === selectedCity.value.key;
        const keywordMatch = !searchKeyword.value.key || (item[searchKeyword.value.key].includes(search.value));

        return regionMatch && cityMatch && keywordMatch;
    });
});

const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredList.value.slice(start, end);
});


const isModalOpen = ref(false);
const modalTitle = ref("");
const onConfirm = ref(null);

const openModal = (title, confirmAction) => {
    modalTitle.value = title;
    onConfirm.value = confirmAction;
    isModalOpen.value = true;
};

const isOneBtnModalOpen = ref(false);
const modalText = ref("");
const isAlertModal = ref(false);

const openOneBtnModal = (title, text, alert) => {
    modalTitle.value = title;
    modalText.value = text
    isAlertModal.value = alert
    isOneBtnModalOpen.value = true;
};

/* 업로드 파일 양식 다운로드 */
const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/templates/한시적 주차구역 등록 양식.xlsx';
    link.download = '쉽차장-한시적 주차구역 등록 양식.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/* 한시적 주차구역 엑셀 업로드 */
const fileInput = ref(null);
const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0]; // 선택한 파일 저장

    const formData = new FormData();
    formData.append('file', selectedFile); // 'file'은 서버에서 받을 key 값

    try {
        await store.dispatch('tempParking/addTempParkingList', formData)
        openOneBtnModal('한시적 주차구역이 성공적으로 등록되었습니다.', null);
    } catch (error) {
        openOneBtnModal('한시적 주차구역 등록 중 에러가 발생했습니다.', "등록 파일을 다시 한번 확인해주세요.", true);
    } finally {
        fileInput.value = null;
        event.target.value = "";
    }
};

/* 다중 주차구역 삭제 */
const confirmDelete = () => {
    if (selectedTempParking.value.length > 0) {
        let title = `선택한 ${selectedTempParking.value.length}개의 주차장을 삭제하시겠습니까?`;
        openModal(title, deleteSelectedItems);
    } else {
        // 선택된 항목이 없는 경우 경고
        openOneBtnModal("삭제할 주차장을 선택해주세요.", null, true);
    }
};

const deleteSelectedItems = async () => {
    try {
        await store.dispatch('tempParking/deleteMultipleTempParking', selectedTempParking.value);
        selectedTempParking.value = []; // 선택 항목 초기화
        openOneBtnModal("성공적으로 삭제되었습니다.", null, false);
    } catch (error) {
        openOneBtnModal("삭제 중 오류가 발생했습니다.", null, true);
    }
};

/* 한시적 주차구역 삭제 */
const deleteTempParking = async () => {
    try {
        await store.dispatch('tempParking/deleteTempParking', selectedRow.value.id);
        store.dispatch('tempParking/getTempParkingList');
        selectedRow.value = null
        openOneBtnModal("성공적으로 삭제되었습니다.", null, false);
    } catch (error) {
        openOneBtnModal("삭제 중 오류가 발생했습니다.", null, true);
    }
}

const openDetail = (row) => {
    selectedRow.value = JSON.parse(JSON.stringify(store.getters['tempParking/getTempParkingById'](row.id)));
}
</script>
