<template>
    <!-- qanda중 미답변 테이블 상세페이지 -->
    
    <div>
        <h4 class="listTitle">문의내역</h4>
        <div class="table-wrapper">
            <div class="btn-wrapper mb-3">
                <button class="black-btn" @click="goBack">←</button>
                <button class="blue-btn" @click="checkAnswer">답변등록</button>
                <button class="red-btn" @click="isCancelModalOpen = true">취소</button>
            </div>
            <table class="qandaTable" align="center">
                <colgroup>
                    <col style="width: 10%;" />
                    <col style="width: 90%;" />
                </colgroup>
                <tr>
                    <td>제목</td>
                    <td>{{ inquiryData.title }}</td>
                </tr>
                <tr>
                    <td>작성날짜</td>
                    <td>{{ inquiryData.formattedCreatedDate }}</td>
                </tr>
                <tr>
                    <td>작성자</td>
                    <td>{{ inquiryData.writerName }}</td>
                </tr>
                <tr class="question-row">
                    <td class="large-cell">문의내역</td>
                    <td>{{ inquiryData.content }}</td>
                </tr>
                <tr class="answer-row">
                    <td class="large-cell">답변</td>
                    <td>
                        <textarea v-model="answer" class="answer-textarea" placeholder="답변을 입력하세요" autofocus></textarea>
                    </td>
                </tr>
    
            </table>
        </div>
    
        <!-- 답변등록모달 -->
        <b-modal v-model="isConfirmModalOpen" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="modalTitle">답변을 등록하시겠습니까?</p>
                </div>
                <div class="modalBtn">
                    <!-- "네" 버튼 클릭 시 createAnswer 메서드 호출 -->
                    <b-button class="modal-blue-btn" @click="postAnswer">네</b-button>
                    <b-button class="modal-red-btn" @click="isConfirmModalOpen = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
    
        <!-- 답변취소모달 -->
        <b-modal v-model="isCancelModalOpen" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="modalTitle">답변을 등록 취소하시겠습니까?</p>
                    <p class = "modalText">변경된 사항은 저장되지 않습니다.</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="goBack">네</b-button>
                    <b-button class="modal-red-btn" @click="isCancelModalOpen = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();
const route = useRoute();

const goBack = () => {
    router.back(); // 브라우저의 뒤로 가기 기능 실행
};

const isCancelModalOpen = ref(false);
const isConfirmModalOpen = ref(false);
const answer = ref('');

const inquiryData = computed(() => store.getters['inquiry/getInquiryById'](route.params.id));

onMounted(() => {
    console.log(inquiryData.value)
    if (!inquiryData.value) {
        console.warn("🚨 Vuex에 데이터가 없습니다.");
        goBack();
    }
});

const checkAnswer = () => {
    if (!answer.value.trim()) {
        // 답변이 비어 있는지 확인
        Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
            imageWidth: 55,
            imageHeight: 55,
            text: "답변 내용을 입력해주세요.",
            confirmButtonColor: "#5B67EC",
            confirmButtonText: "확인",

        });
        return; // answer가 비어 있으면 저장 중단
    } else {
        isConfirmModalOpen.value = true;
    }
}
const postAnswer = () => {
    try {
        store.dispatch('inquiry/postInquiryAnswer', { id: route.params.id, answer: answer.value.trim() });
        isConfirmModalOpen.value = false; // 모달 닫기
        goBack();
    } catch (error) {
        console.error('답변 등록 실패:', error);
        // 필요한 경우 오류 처리를 추가하세요
    }
};
</script>

<style>
.answer-textarea:focus {
    border-color: #5B67EC;
    box-shadow: 0 0 5px rgba(91, 103, 236, 0.5);
    transition: box-shadow 0.3s ease;
}
</style>
