<template>
    <div class="date-range-picker">
        <div class="period-options">
            <button v-for="option in props.periodOptions" :key="option.value" :class="{ active: activePeriod === option.value }" @click="setPeriod(option.value)">
                        {{ option.label }}
                    </button>
        </div>
        <div class="custom-dates">
            <input type="date" v-model="startDateInput" placeholder="시작 날짜" @input="handleCustomDateChange('start')" />
            <p style="margin: 0; padding: 0 10px"> ~ </p>
            <input type="date" v-model="endDateInput" placeholder="종료 날짜" @input="handleCustomDateChange('end')" />
        </div>
    </div>
</template>
  
<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';

const formatDate = (date) => {
    return date.toISOString().split("T")[0];
};

const props = defineProps({
    periodOptions: Array,
});

const emit = defineEmits(['updateDates']);
const activePeriod = ref(props.periodOptions[0].value);
const startDate = ref(new Date());
const endDate = ref(new Date());

// 사용자가 입력한 custom 날짜를 저장하는 변수
const startDateInput = ref(formatDate(startDate.value));
const endDateInput = ref(formatDate(endDate.value));


const setPeriod = (period) => {
    activePeriod.value = period;
    const now = new Date();

    switch (activePeriod.value) {
        case "thisMonth":
            startDate.value = new Date();
            startDate.value.setDate(1);
            endDate.value = now;
            break;
        case "today":
            startDate.value = now;
            endDate.value = now;
            break;
        case "1month":
            startDate.value = new Date();
            startDate.value.setMonth(now.getMonth() - 1);
            endDate.value = now;
            break;
        case "3months":
            startDate.value = new Date();
            startDate.value.setMonth(now.getMonth() - 3);
            endDate.value = now;
            break;
        case "6months":
            startDate.value = new Date();
            startDate.value.setMonth(now.getMonth() - 6);
            endDate.value = now;
            break;
        case "1years":
            startDate.value = new Date();
            startDate.value.setYear(now.getFullYear() - 1);
            endDate.value = now;
            break;
    }

    startDateInput.value = formatDate(startDate.value);
    endDateInput.value = formatDate(endDate.value);

    emit('updateDates', { startDate: startDateInput.value, endDate: endDateInput.value });
};

// 사용자가 직접 날짜 입력 시 동작
const handleCustomDateChange = (type) => {
    activePeriod.value = null; // 버튼 선택 해제

    if (type === "start") {
        startDate.value = new Date(startDateInput.value);
    } else {
        endDate.value = new Date(endDateInput.value);
    }
    emit('updateDates', { startDate: startDateInput.value, endDate: endDateInput.value });
};

onMounted(async () => {
    setPeriod(activePeriod.value)
})

</script>
  
<style scoped>
.date-range-picker {
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    margin: 0 10px;
}

.date-picker-label {
    font-weight: bold;
    margin: 0px 20px;
}

.period-options {
    display: flex;
    cursor: pointer;
    margin: 0 0.5rem 0 0 ;
}

.period-options button {
    border: 1px solid #e2e6ea;
    color: #6C757D;
    background-color: white;
    border-radius: 0.625rem;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem 0 0 ;
    cursor: pointer;
}

.period-options button.active {
    background-color: #6C757D;
    color: white;
    text-align: center;
}

.custom-dates {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.custom-dates input {
    padding: 0.5rem;
    border-radius: 0.625rem;
    border: 1px solid #e2e6ea;
}
</style>