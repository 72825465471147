import { createWebHistory, createRouter } from "vue-router";
import parkingList from "@/pages/parkingList.vue";
import temporaryParking from "@/pages/temporaryParking.vue";
import noticeBoard from "@/pages/noticeBoard.vue";
import noticeDetail from "@/pages/noticeDetail.vue";
import NoticeWrite from "@/pages/noticeWrite.vue";

import QandA from "@/pages/QandA.vue";
import QandAdetail from "@/pages/QandAdetail.vue";
import QandAedit from "@/pages/QandAedit.vue";

import dashBoard from "@/pages/dashBoard.vue";
import parkinglotTip from "@/pages/parkinglotTip.vue";
import reserveList from "@/pages/reserveList.vue";
import ownerManage from "@/pages/ownerManage.vue";
import salesData from "@/pages/salesData.vue";
import unAnswered from "@/pages/QandAedit.vue";
import deviceList from "@/pages/deviceList.vue";
import editAnswer from "@/pages/editAnswer.vue";
import loginPage from "@/pages/loginPage.vue";
import tipDetail00 from "@/pages/tipDetail00.vue";
// import tipDetail01 from "@/pages/tipDetail01.vue";
import parkingLayout from "@/pages/parkingLayout.vue";
import reserveLayout from "@/pages/reserveLayout.vue"
import WebViewSeatSelect from "@/pages/WebViewSeatSelect.vue";
import TestseatSelect from "@/pages/TestseatSelect.vue";
import TestmyreservSeat from "@/pages/TestmyreservSeat.vue";
import WebViewMySeat from "@/pages/WebViewMySeat.vue";
import store from '@/store';
import ParkingProcessBoard from "@/pages/parkingProcessBoard.vue";
const routes = [
  {
    path: "/",
    redirect: "/loginPage", // 기본 경로를 로그인 페이지로 리디렉션
  },
  {
    path: "/loginPage",
    component: loginPage,
    meta: { hideComponent: true },
  },
  {
    path: "/parkingList",
    component: parkingList,
    name: "parkingList",
    meta: { panel: "clickedInfo", isTemporary: false },
  },
  {
    path: "/temporaryParking",
    component: temporaryParking,
    meta: { panel: "clickedInfo2", isTemporary: true },
  },
  {
    path: "/noticeBoard",
    component: noticeBoard,
  },
  {
    path: "/noticeWrite",
    component: NoticeWrite,
  },
  {
    path: "/noticeDetail/:id",
    name: "noticeDetail",
    component: noticeDetail,
    beforeEnter: (to, from, next) => {
      const notice = store.getters['notice/getNoticeById'](to.params.id);
      if (!notice) {
        console.warn("🚨 Vuex에 데이터가 없습니다. API에서 데이터를 다시 불러와야 합니다.");
        // ❗ API 호출 예시: store.dispatch("notice/fetchNoticeById", to.params.id).then(() => next())
      }
      next();
    }
  },
  {
    path: "/QandA",
    component: QandA,
  },
  {
    path: "/QandAdetail/:id",
    name: "QandAdetail",
    component: QandAdetail,
    beforeEnter: (to, from, next) => {
      const data = store.getters['inquiry/getInquiryById'](to.params.id);
      if (!data) {
        console.warn("🚨 Vuex에 데이터가 없습니다. API에서 데이터를 다시 불러와야 합니다.");
        // ❗ API 호출 예시: store.dispatch("notice/fetchNoticeById", to.params.id).then(() => next())
      }
      next();
    }
  },
  {
    path: "/QandAedit/:id",
    name: "QandAedit",
    component: QandAedit,
  },
  {
    path: "/dashboard",
    component: dashBoard,
  },
  {
    path: "/parkinglotTip",
    component: parkinglotTip,
  },
  {
    path: "/tipDetail00/:id",
    component: tipDetail00,
    name: "tipDetail00"
  },
  // {
  //   path: "/tipDetail01/:id",
  //   component: tipDetail01,
  //   name: "tipDetail01"
  // },
  {
    path: "/parkingProcess",
    component: ParkingProcessBoard
  },

  {
    path: "/reseveList",
    component: reserveList,
  },
  {
    path: "/ownerManage",
    component: ownerManage,
    meta: { panel: "clickedInfo3" },
  },
  {
    path: "/salesData",
    component: salesData,
  },
  {
    path: "/unAnswered",
    component: unAnswered,
  },
  {
    path: "/deviceList",
    component: deviceList,
    meta: { panel: "clickedInfo3" },
  },
  {
    path: "/editAnswer",
    component: editAnswer,
  },
  {
    path: "/parkingLayout",
    component: parkingLayout,
    meta: {
      hideComponent: true
    }
  },
  {
    path: "/reserveLayout",
    component: reserveLayout,
    meta: { hideComponent: true },
  },
  {
    path: "/seat",
    component: WebViewSeatSelect,
    meta: { hideComponent: true },
  },
  {
    path: "/myseat",
    component: WebViewMySeat,
    meta: { hideComponent: true }
  },
  {
    path: "/testseat",
    component: TestseatSelect,
    meta: { hideComponent: true },
  },
  {
    path: "/myreserve",
    component: TestmyreservSeat,
    meta: { hideComponent: true },
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
