<template>
  <!-- 답변완료된 답변을 수정하는 페이지 -->
      <h4 class="listTitle">문의내역</h4>
      <div class="table-wrapper">
        <div class="btn-wrapper">
          
          <b-button variant="outline-primary" @click="답변저장모달 = true">답변저장</b-button>
          <b-button variant="outline-danger" @click="답변삭제모달 = true">삭제</b-button>
          <b-button variant="outline-danger" @click="답변수정취소모달 = true">취소</b-button>
        </div>
  
        <b-modal v-model="답변저장모달" hide-footer hide-header centered>
          <div class="modalContents">
            <div class="modalImageWrapper">
              <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
            </div>
            <div class="modalTextWrapper">
              <p class="mainAnswer">답변을 수정하시겠습니까?</p>
            </div>
            <div class="modalBtn">
              <b-button class="modal-blue-btn" @click="등록">네</b-button>
              <b-button class="modal-red-btn" @click="답변저장모달 = false">아니오</b-button>
            </div>
          </div>
        </b-modal>
  
        <b-modal v-model="답변수정취소모달" hide-footer hide-header centered>
          <div class="modalContents">
            <div class="modalImageWrapper">
              <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
            </div>
            <div class="modalTextWrapper">
              <p class="mainAnswer">답변 수정을 취소하시겠습니까?</p>
              <p>변경된 사항은 저장되지 않습니다</p>
            </div>
            <div class="modalBtn">
              <b-button class="modal-blue-btn" @click="취소">네</b-button>
              <b-button class="modal-red-btn" @click="답변수정취소모달 = false">아니오</b-button>
            </div>
          </div>
        </b-modal>
  
        <table class="qandaTable" align="center">
          <colgroup>
            <col style="width: 16.67%;" />
            <col style="width: 83.33%;" />
          </colgroup>
          <tr class="title-row">
            <td>제목</td>
            <td></td>
          </tr>
          <tr>
            <td>작성날짜</td>
            <td></td>
          </tr>
          <tr>
            <td>작성자</td>
            <td></td>
          </tr>
          <tr>
            <td class="large-cell">문의내역</td>
            <td></td>
          </tr>
          <tr class="answer-row">
            <td class="large-cell">답변</td>
            <!-- textarea를 항상 렌더링하여 답변을 입력할 수 있게 함 -->
            <td>
              <textarea v-model="answer" class="answer-textarea"></textarea>
            </td>
          </tr>
        </table>
      </div>
  
  </template>
  
  <script>
  export default {
    data() {
      return {
        answer: '', // 사용자가 수정할 답변 내용
        답변저장모달: false,
        답변수정취소모달: false,
      };
    },
    methods: {
      등록() {
        // 답변 저장 로직 추가
        this.답변저장모달 = false;
      },
      취소() {
        this.답변수정취소모달 = false;
        this.answer = ''; // 수정 취소 시 답변 내용을 초기화
      },
    },
  };
  </script>
  
  <style>
  .qandaTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 1.125rem;
    overflow: hidden;
    background-color: white;
  }
  
  .qandaTable td {
    border: 1px solid #ccc;
    padding: 8px;
  }
  
  .qandaTable tr:last-child td:first-child {
    border-bottom-left-radius: 1.125rem; /* 왼쪽 하단 모서리 둥글게 */
  }
  
  .qandaTable tr:last-child td:last-child {
    border-bottom-right-radius: 1.125rem; /* 오른쪽 하단 모서리 둥글게 */
  }
  
  .qandaTable tr:first-child td:first-child {
    border-top-left-radius: 1.125rem; /* 왼쪽 상단 모서리 둥글게 */
  }
  
  .qandaTable tr:first-child td:last-child {
    border-top-right-radius: 1.125rem; /* 오른쪽 상단 모서리 둥글게 */
  }
  
  .qandaTable .title-row td {
    border-bottom: solid 2px #ccc;
  }
  
  .qandaTable .answer-row td {
    border-top: double 2px #ccc;
  }
  
  .large-cell {
    height: 150px; /* 행의 높이를 조정 */
  }
  
  .answer-textarea {
    width: 100%; 
    height: 100%; /* 높이 조정 */
    padding: 0.3rem; 
    font-size: 1rem; 
    border-radius: 8px;
    border: none;
    outline: none;
    box-sizing: border-box;
    resize: none; /* 크기 조정 불가 */
  }
  </style>
  