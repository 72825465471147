import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatFullDateTime } from '@/utils/stringFormatter';

export default {
    namespaced: true,

    state: {
        reportList: [],
        reportType: null,
    },

    getters: {
        reportList: (state) => state.reportList.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)).map(item => ({
            ...item,
            formattedStatus: {
                PENDING: "답변 대기",
                COMPLETE: "승인",
                REJECTED: "거절"
            }[item.status] || "알 수 없음",
            formattedCreatedDate: formatFullDateTime(item.createdDate),
            formattedUpdateDate: {
                PENDING: "-"
            }[item.status] || formatFullDateTime(item.resolvedDate)
        })),
        getReportById: (state) => (id) => {
            const item = state.reportList.find(n => n.id == id);

            // 값이 모두 null인지 확인
            const isAllNull = [item.firstMinute, item.firstFee, item.extraMinute, item.extraFee].every(value => value == null);

            return {
                ...item,
                formattedStatus: {
                    PENDING: "",
                    COMPLETE: "resolve",
                    REJECTED: "reject"
                }[item.status] || "알 수 없음",
                formattedCreatedDate: formatFullDateTime(item.createdDate),
                formattedFee: isAllNull ? "-" : `기본 ${item.firstMinute ?? "-"}분 ${item.firstFee ?? "-"}원, 초과 ${item.extraMinute ?? "-"}분 ${item.extraFee ?? "-"}원`
            }
        }
    },

    mutations: {
        SET_REPORT_LIST(state, { data, type }) {
            state.reportList = data;
            state.reportType = type
        },
    },

    actions: {
        // 제보내역 리스트 조회
        async getReportList({ commit }, {type, startDate, endDate}) {
            try {
                const response = await basicInstance.get(`/parking-requests/${type}`,{
                    params: {
                        startDate,
                        endDate
                    },
            });
                commit('SET_REPORT_LIST', { data: response.data.list, type: type });
            } catch (error) {
                console.error('제보내역 조회 실패:', error);
                throw error;
            }
        },

        // 제보내역 승인
        async postReportAnswer({ state, dispatch }, { id, answer, status }) {
            console.log("제보내역:", state.reportType, id, answer, status);
            try {
                await basicInstance.post(`/parking-requests/${state.reportType}/${id}/${status}`, { answer });
                await dispatch('getReportList', state.reportType);
            } catch (error) {
                console.error('제보내역 승인 에러:', error.response ? error.response.data : error.message);
            }
        },

        // 제보내역 거절
        async rejectReport({ state, dispatch }, { reportType, id, answer }) {
            console.log("제보내역:", reportType, answer);
            try {
                await basicInstance.post(`/parking-requests/${reportType}/${id}/reject`, { answer });
                await dispatch('getReportList', state.reportType);
            } catch (error) {
                console.error('제보내역 거절 에러:', error.response ? error.response.data : error.message);
            }
        },
    }
}
