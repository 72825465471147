<template>
  <div class="sidebar">
    <div class="mainLogo mt-3 mb-5">
      <img src="@/assets/icons/shipchajang.svg" alt="" />
    </div>
    <div class="sidebarMenus">
      <div 
        v-for="(menu, index) in menus" 
        :key="index" 
        @mouseover="hoveredIndex = index"
        @mouseleave="hoveredIndex = null"
        @click="handleClick(index, menu.path)"
        :class="{ 
          'active-background': hoveredIndex === index ||$route.path === menu.path ||clickedIndex === index 
        }"
        class="menu-link"
        >
        <img :src="($route.path === menu.path ||hoveredIndex === index) ? menu.hoverIcon : menu.icon" />
        <span class="menu-text">{{ menu.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipChajang',
  data() {
    return {
      hoveredIndex: null,
      clickedIndex: null,
      menus: [
        { name: '대시보드', icon: require('@/assets/icons/Dashboard_Icon-Black.svg'), hoverIcon: require('@/assets/icons/Dashboard_Icon-White (1).svg'),path:"/dashboard" },
        { name: '주차장 리스트', icon: require('@/assets/icons/주차장리스트Icon-Black.svg'), hoverIcon: require('@/assets/icons/주차장Icon-White.svg'),path:"/parkingList" },
        { name: '한시적 주차 허용', icon: require('@/assets/icons/한시적 주차Icon-Black.svg'), hoverIcon: require('@/assets/icons/한시적 주차Icon-White.svg'),path:"/temporaryParking" },
        { name: '공지사항', icon: require('@/assets/icons/공지사항Icon-Black.svg'), hoverIcon: require('@/assets/icons/공지사항Icon-White.svg'),path:"/noticeBoard" },
        { name: '1:1 문의내역', icon: require('@/assets/icons/문의내역Icon-Black.svg'), hoverIcon: require('@/assets/icons/문의내역Icon-White.svg'),path:"/QandA" },
        { name: '주차장 제보', icon: require('@/assets/icons/제보Icon-Black.svg'), hoverIcon: require('@/assets/icons/제보Icon-White.svg'),path:"/parkinglotTip" },
        { name: '주차 이용 리스트', icon: require('@/assets/icons/예약리스트Icon-Black.svg'), hoverIcon: require('@/assets/icons/예약리스트Icon-White.svg'),path:"/parkingProcess" },
        { name: '주차장 소유자 관리', icon: require('@/assets/icons/주차장소유자Icon-Black.svg'), hoverIcon: require('@/assets/icons/주차장소유자Icon-White.svg'),path:"/ownerManage" },
        { name: '매출 통계', icon: require('@/assets/icons/매출통계Icon-Black.svg'), hoverIcon: require('@/assets/icons/매출통계Icon-White.svg'),path:"/salesData" },
        // { name: '기기 관리', icon: require('@/assets/icons/deviceSetting.svg'), hoverIcon: require('@/assets/icons/deviceHover.svg'),path:"/deviceList" },
      ],
    };
  },
  created() {
    // 페이지 로드 시 현재 경로에 따라 clickedIndex 설정
    this.updateClickedIndex();
},
watch: {
    '$route.path'() {
      this.updateClickedIndex();
    },
  },
  methods: {
  updateClickedIndex() {
    const currentPath = this.$route.path;
    this.clickedIndex = this.menus.findIndex(menu => menu.path === currentPath);

    if (currentPath === '/dashboard') {
      this.clickedIndex = 0;
    }
  },
  handleClick(index, path) {
    this.clickedIndex = index;
    this.$router.replace(path); 
  }
}
}
</script>

<style>

.sidebar {
  position: sticky; 
  padding: 2rem 0 0 1.875rem;
  /* margin-left: 1.875rem;; */
  height: 100vh;
}

.mainLogo {
  width: 100%;
  text-align:start;
}

.sidebarMenus {
  text-align:start;
}

.sidebarMenus div {
  margin-right: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0.7rem;
  border-radius:  0.5rem;
  transition: background-color 0.3s;
}

.active-background {
  background-color: #5B67EC;
  color: white !important;
}

.sidebarMenus .menu-link {
  display: flex;
  align-items: center;
  text-decoration: none; 
  color: inherit; 
}

.sidebarMenus .menu-text {
  padding-left: 1.25rem;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* 특정 화면 크기 이하에서는 텍스트 숨김 */
@media (max-width: 768px) {
  .sidebarMenus .menu-text {
    display: none;
  }
}
</style>
