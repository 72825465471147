import store from '@/store'; // Vuex 스토어 (토큰 관리용)
import router from '@/router'; // Vue Router (로그인 페이지 이동용)

let isRefreshing = false; // 🔥 토큰 갱신 요청 여부 플래그
let refreshSubscribers = []; // 🔥 기존 요청을 저장하여, 토큰 갱신 후 한 번에 처리

export default function setInterceptors(instance) {
    if (!instance) {
        console.error('인터셉터 생성 오류: instance가 없습니다.');
        return instance;
    }
    const noAuthPaths = ['/auth/admin', '/auth/reissue'];

    // 요청 인터셉터: 토큰이 있으면 Authorization 헤더에 추가
    instance.interceptors.request.use((config) => {
        if (!noAuthPaths.includes(config.url)) {
            const token = store.getters['auth/getAccessToken'];
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        store.dispatch("showLoading");
        return config;

    },
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use((response) => {
        store.dispatch("hideLoading");
        return response
    }, async (error) => {
        store.dispatch("hideLoading");
        const { response, config } = error;
        console.log(response.data)
        if (response?.status === 401) {
            if (isRefreshing) {
                return new Promise((resolve) => {
                    refreshSubscribers.push((newToken) => {
                        config.headers.Authorization = `Bearer ${newToken}`;
                        resolve(instance(config)); // 🔥 기존 요청 재시도
                    });
                });
            }

            isRefreshing = true;

            try {
                const newAccessToken = await store.dispatch('auth/refreshToken'); // 토큰 갱신 API 호출

                // ✅ 기존 대기 요청들 실행
                refreshSubscribers.forEach((callback) => callback(newAccessToken));
                refreshSubscribers = []; // 초기화

                config.headers.Authorization = `Bearer ${newAccessToken}`;
                return instance(config); // 요청 재시도
            } catch (refreshError) {
                console.error('🚨 토큰 갱신 실패:', refreshError);
                router.push('/loginPage'); // 로그인 페이지로 이동
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    }
    );

    return instance; // ✅ 반드시 반환해야 함
}