<template>
    <div>
        <h4 class="listTitle">주차 이용 리스트</h4>
        <div class="table-wrapper">
            <div class="header-wrapper">
                <div class="left-header">
                    <div class="btn-wrapper mt-2">
                        <DateRangePicker style="margin: 0;" ref="dateRangePicker" :periodOptions="periodOptions" @updateDates="handleDateUpdate" />
                        <button class="blue-btn" @click="getParkingProcess">조회</button>
                    </div>
                    <button class="blue-btn mt-2" @click="downloadExcel">이용 내역 다운로드</button>
                </div>
                <div class="right-header">
                    <div class="search-wrapper">
                        <b-dropdown :text="searchKeyword.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item) in keywordOptions" :key="item" @click="searchKeyword = item" class="custom-dropdown">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <div class="search-layout">
                            <input v-model="search" @input="handleSearchInput" type="text" placeholder="검색어를 입력하세요" />
                            <i class="bi bi-search"></i>
                        </div>
                        <!--
                        <select v-model="searchKeyword" class="custom-dropdown" style="margin-right: 10px;">
                            <option v-for="option in optionsKeyword" :key="option.value" :value="option.value">
                            {{ option.text }}
                            </option>
                        </select>
                        <input v-model="search" @input="handleSearchInput" class="search-input" type="text" placeholder="검색어를 입력하세요" />
                        <button type="submit" class="search-button">
                            <img src="../assets/icons/SearchIcon.svg" alt="Search">
                        </button> -->
                    </div>
                    <div class="filter-btn mt-2">
                        <button @click="filterBtn('PARKING')" :class="{ 'selected': selectedFilter === 'PARKING' }">주차중</button>
                        <button @click="filterBtn('COMPLETED')" :class="{ 'selected': selectedFilter === 'COMPLETED' }">출차 완료</button>
                        <button @click="filterBtn('FAILURE_PAYMENT')" :class="{ 'selected': selectedFilter === 'FAILURE_PAYMENT' }">결제 실패</button>
                        <button @click="filterBtn('PARTIAL_CANCELED')" :class="{ 'selected': selectedFilter === 'PARTIAL_CANCELED' }">부분 취소</button>
                        <button @click="filterBtn('CANCELED')" :class="{ 'selected': selectedFilter === 'CANCELED' }">결제 취소</button>
                    </div>
                </div>
            </div>
    
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="false" @row-clicked="openDetail" />
            <div v-if="paginatedItems.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="filteredList.length" align="center" aria-controls="my-table">
                </b-pagination>
            </div>
        </div>
    
        <div class="overlay" v-if="selectedRow">
            <div class="backDrop" @click="selectedRow = null" />
            <div class="sidebar-wrapper">
                <button class="close-btn" @click="selectedRow = null">X</button>
    
                <div class="mt-5">
                    <div class="sidebar-item-container">
                        <p class="modalText">주차장 이름</p>
                        <p class="modalText">{{ selectedRow?.parkingName || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">예약자</p>
                        <p class="modalText">{{ selectedRow?.userName || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">연락처</p>
                        <p class="modalText">{{ selectedRow?.userPhone || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">주차 시작 시간</p>
                        <p class="modalText">{{ selectedRow?.formattedEntryTime || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">주차 종료 시간</p>
                        <p class="modalText">{{ selectedRow?.formattedDepartTime || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">주차요금</p>
                        <p class="modalText">{{ selectedRow?.formattedFee || "-"}} 원</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">사용 포인트</p>
                        <p class="modalText">{{ selectedRow?.formattedPoint}}</p>
                    </div>

                    <div v-if="selectedRow?.cancelAmount != '-'" class="sidebar-item-container">
                        <p class="modalText" style="color:red">결제 취소금액</p>
                        <p class="modalText" style="color:red"> {{ selectedRow?.cancelAmount || "-"}} 원</p>
                    </div>

                    <div class="sidebar-item-container">
                        <p class="modalText">실 결제금액</p>
                        <p class="modalText">{{ selectedRow?.formattedPrice || "0"}} 원</p>
                    </div>
    
                    <div class="form-group mt-5">
                        <button class="modal-blue-btn" @click="isPhotoVisible = true">입차 사진 보기</button>
                        <button v-if="selectedRow?.cancelable" class="modal-red-btn mt-3" @click="isRefundPopupOpen = true">결제 취소</button>
                    </div>
                </div>
            </div>
        </div>
    
        <b-modal v-model="isPhotoVisible" hide-footer hide-header centered>
            <button type="button" class="btn-close" aria-label="Close" @click="isPhotoVisible=false"></button>
            <div class="popupImage mt-5">
                <img v-if="selectedRow && selectedRow.imageUrl" :src="selectedRow.imageUrl" alt="이미지" />
            </div>
        </b-modal>
    
        <b-modal v-model="isRefundPopupOpen" hide-header hide-footer centered>
            <p class="modalTitle mt-0">결제 취소</p>
            <div class="modalContainer p-2">
                <button type="button" class="btn-close" aria-label="Close" @click="isRefundPopupOpen=false"></button>
                <div class="sidebar-item-container">
                    <p class="modalText">주차장 이름</p>
                    <p class="modalText">{{ selectedRow?.parkingName || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">예약자</p>
                    <p class="modalText">{{ selectedRow?.userName || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">연락처</p>
                    <p class="modalText">{{ selectedRow?.userPhone || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">주차 시작 시간</p>
                    <p class="modalText">{{ selectedRow?.formattedEntryTime || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">주차 종료 시간</p>
                    <p class="modalText">{{ selectedRow?.formattedDepartTime || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">실 결제금액</p>
                    <p class="modalText">{{ selectedRow?.formattedPrice || "-"}} 원</p>
                </div>
                <div class="modalTextWrapper">
                    <div class="popupInput mt-4">
                        <label>결제 취소 사유</label>
                        <input type="text" v-model="refundReason" />
                    </div>
                    <div class="popupInput mt-4">
                        <label>결제 취소 금액</label>
                        <input type="number" v-model="partialRefundPrice" :disabled="fullRefund" />
                    </div>
                    <div>
                        <label class="mx-1">
                        <input type="checkbox" v-model="fullRefund" class="custom-checkbox" @change="toggleValue"/>전체 금액 취소</label>
                    </div>
                    <div class="popupInput my-4">
                        <label>2차 비밀번호</label>
                        <input type="text" v-model="password" />
                    </div>
                </div>
                <div class="popup-btnLayout">
                    <button class="modal-blue-btn" @click="checkInputField">결제 취소하기</button>
                </div>
            </div>
        </b-modal>

        <confirmModal v-model="isModalOpen" :modalTitle="modalTitle" :onConfirm="onConfirm" />

    </div>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect, reactive } from 'vue';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';
import confirmModal from '@/components/confirmModal.vue';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

onMounted(() => {
    store.dispatch('parkingProcess/getParkingProcessList', { startDate: startDate.value, endDate: endDate.value });
});

const store = useStore();

const startDate = ref('');
const endDate = ref('');
const periodOptions = reactive([
    { label: "오늘", value: "today" },
    { label: "이번달", value: "thisMonth" },
    { label: "3개월", value: "3months" },
    { label: "6개월", value: "6months" },
]);

const handleDateUpdate = ({ startDate: newStart, endDate: newEnd }) => {
    startDate.value = newStart;
    endDate.value = newEnd;
};

const getParkingProcess = async () => {
    await store.dispatch('parkingProcess/getParkingProcessList', { startDate: startDate.value, endDate: endDate.value });
};

const currentPage = ref(1);
const perPage = ref(15);
const selectedRow = ref(null);
const tableHeaders = reactive([
    { key: 'parkingName', title: '주차장 이름', width: '5rem' },
    { key: 'userName', title: '예약자', width: '4rem' },
    { key: 'userPhone', title: '연락처', width: '5rem' },
    { key: 'entryDateTime', title: '주차 시작 시간', width: '5rem' },
    { key: 'departureDateTime', title: '주차 종료 시간', width: '5rem' },
    { key: 'formattedFee', title: '주차 요금', width: '3rem' },
    { key: 'formattedPoint', title: '포인트', width: '2rem' },
    { key: 'formattedPrice', title: '결제 금액', width: '3rem' },
    { key: 'formattedStatus', title: '주차 상태', width: '3rem' },
]);

const parkingProcessList = computed(() => store.getters['parkingProcess/parkingProcessList']);
const filteredList = ref([]);

const keywordOptions = [
    { key: 'parkingName', label: '주차장 이름' },
    { key: 'userName', label: '예약자' },
];

const searchKeyword = ref({ key: 'parkingName', label: '주차장 이름' });
const search = ref('');
const selectedFilter = ref(null);
const filterBtn = (type) => {
    selectedFilter.value = selectedFilter.value === type ? null : type
}

const handleSearchInput = (event) => {
    search.value = event.target.value
}

const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredList.value.slice(start, end);
});

const downloadExcel = () => {
    // 테이블 데이터를 배열 형태로 준비
    const dataToExport = filteredList.value.map(item => ({
        '주차장 이름': item.parkingName,
        '예약자': item.userName,
        '연락처': item.userPhone,
        '입차 시간': item.entryDateTime,
        '출차 시간': item.departureDateTime,
        '주차 요금': item.totalAmount,
        '실결제': item.amount,
        '주차상태': item.formattedStatus,
    }));

    // 워크북과 시트 생성
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();

    // 시트를 워크북에 추가
    XLSX.utils.book_append_sheet(workbook, worksheet, "주차 이용 리스트");

    // 파일을 엑셀 형식으로 다운로드
    XLSX.writeFile(workbook, "주차이용리스트.xlsx");
};

const openDetail = (row) => {
    selectedRow.value = store.getters['parkingProcess/getParkingProcessById'](row.id);
}

const isModalOpen = ref(false);
const modalTitle = ref("");
const onConfirm = ref(null);

const checkInputField = () => {
    if (!password.value || !refundReason.value || (!fullRefund.value && !partialRefundPrice.value)) {
        Swal.fire({
            text: "취소 관련 내용을 전부 입력해주세요.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",
        });
        return;
    }

    if (partialRefundPrice.value >= selectedRow.value.amount) {
        Swal.fire({
            text: "결제 취소 금액은 실 결제금액보다 클 수 없습니다.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",
        });
        return;
    }

    openModal('결제를 취소하시겠습니까?', postRefund)
};

const openModal = (title, confirmAction) => {
    modalTitle.value = title;
    onConfirm.value = confirmAction;
    isModalOpen.value = true;
};

const isPhotoVisible = ref(false);
const isRefundPopupOpen = ref(false);
const fullRefund = ref(true);
const refundReason = ref(null);
const partialRefundPrice = ref(null);
const password = ref(null);
const toggleValue = () => {
    if (fullRefund.value) {
        partialRefundPrice.value = null; // 체크하면 값 초기화
    }
};

const postRefund = async () => {
    const data = {
        "password": password.value,
        "cancelReason": refundReason.value,
        //전액 환불일때 null
        //개발환경에서 부분 취소는 지원하지 않음
        "cancelAmount": partialRefundPrice.value
    }
    
    try {
        await store.dispatch('parkingProcess/postRefund', { parkingProcessId: selectedRow.value.id, data: data });
        isRefundPopupOpen.value = false
        await getParkingProcess();      
          console.log("취소전", selectedRow.value)

        selectedRow.value = store.getters['parkingProcess/getParkingProcessById'](selectedRow.value.id);
        console.log("취소후", selectedRow.value)
        refundReason.value = null;
        partialRefundPrice.value = null;
        fullRefund.value = false;

    } catch (error) {
        Swal.fire({
            text: error,
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",
        });
    } finally {
        password.value = null;
    }
}; 

watchEffect(() => {
    if (parkingProcessList.value.length === 0) {
        filteredList.value = [];
        return;
    }

    filteredList.value = parkingProcessList.value.filter(item => {
        const filterMatch = !selectedFilter.value || item.status === selectedFilter.value;
        const keywordMatch = !searchKeyword.value.key || (item[searchKeyword.value.key].includes(search.value));

        return filterMatch && keywordMatch;
    });

    if (!isRefundPopupOpen.value) {
        fullRefund.value = true;
        refundReason.value = null;
        partialRefundPrice.value = null
    }
});
</script>

<style>
.filter-btn>button {
    padding: 7px 20px;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 10px;
    margin: 0 0 0 0.5rem;
}

.filter-btn>button.selected {
    border-color: #5b67ec;
    color: #5b67ec;
}

.popupImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupImage img {
    max-width: 100%;
    max-height: 100%;
}

.custom-checkbox {
    vertical-align: middle;
    transform: scale(1.2);
    margin-right: 3px;
}
</style>
