<template>
    <div>
        <h4 class="listTitle">주차장 소유자 관리</h4>
        <div class="table-wrapper">
            <div class="header-wrapper">
                <div>
                    <input type="file" ref="fileInput" @change="handleFileUpload" accept=".xls,.xlsx" hidden />
                    <button class="blue-btn" @click="triggerFileInput">소유자등록</button>
                    <button class="blue-btn mx-2" @click="downloadTemplate">양식다운로드</button>
                    <button class="blue-btn" @click="downloadExcel">소유자 내역 다운로드</button>
                    <button class="red-btn mx-2" @click="isItemSelected">삭제</button>
                </div>
                <div class="filter-wrapper">
                    <div class="search-wrapper">
                        <b-dropdown :text="searchKeyword.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item) in keywordOptions" :key="item" @click="searchKeyword = item" class="custom-dropdown">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <div class="search-layout">
                            <input v-model="search" @input="handleSearchInput" type="text" placeholder="검색어를 입력하세요" />
                            <i class="bi bi-search"></i>
                        </div>
                    </div>
                </div>
            </div>
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="true" v-model:selectedRows="selectedOwners" @row-clicked="openDetail" />
    
            <div v-if="filteredList.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="filteredList.length" align="center" aria-controls="my-table">
                </b-pagination>
            </div>
        </div>
    
        <div class="overlay" v-if="selectedRow">
            <div class="backDrop" @click="selectedRow = null" />
            <div class="sidebar-wrapper">
                <button type="button" class="btn-close" aria-label="Close" @click="selectedRow = null"></button>
    
                <div class="mt-3">
                    <div class="sidebar-item-container" style="border: transparent">
                        <p class="modalText">소유자</p>
                        <p class="modalText">{{ selectedRow?.name || "-"}}</p>
                    </div>
                    <select v-model="selectedAff" class="custom-select mt-2">
                                                    <option v-for="(option) in affParkingList" :key="option" :value="option">
                                                        {{ option.name }}
                                                    </option>
                                                </select>
                    <div class="sidebar-item-container">
                        <p class="modalText">주차장 이름</p>
                        <p class="modalText">{{ selectedAff?.name || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">주차장 주소</p>
                        <p class="modalText">{{ selectedAff?.parkingAddress || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">공유 주차면</p>
                        <p class="modalText">{{ selectedAff?.affiliatedSeat || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">공유 시작 시간</p>
                        <p class="modalText">{{ selectedAff?.operatingTime || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">공유 종료 시간</p>
                        <p class="modalText">{{ selectedAff?.closingTime || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">계약일</p>
                        <p class="modalText">{{ selectedAff?.contractStartDate || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">계약 만료일</p>
                        <p class="modalText">{{ selectedAff?.contractEndDate || "-"}} </p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">AS 만료일</p>
                        <p class="modalText">{{ selectedAff?.asExpireDate || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">정산은행</p>
                        <p class="modalText">{{ selectedAff?.settlementBank || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">정산계좌</p>
                        <p class="modalText">{{ selectedAff?.settlementAccount || "-"}} </p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">수수료(%)</p>
                        <p class="modalText">{{ selectedAff?.charge || "-"}} %</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">상호명</p>
                        <p class="modalText">{{ selectedAff?.businessName || "-"}}</p>
                    </div>
                    <div class="sidebar-item-container">
                        <p class="modalText">사업자등록번호</p>
                        <p class="modalText">{{ selectedAff?.businessRegistrationNumber || "-"}}</p>
                    </div>
                </div>
            </div>
        </div>
    
        <confirmModal v-model="isModalOpen" :modalTitle="modalTitle" :onConfirm="onConfirm" />
        <OneBtnModal v-model="isOneBtnModalOpen" :modalTitle="modalTitle" :modalText="modalText" :alert="isAlertModal" />
    
    </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive, watchEffect } from 'vue';
import confirmModal from '@/components/confirmModal.vue';
import OneBtnModal from '@/components/oneBtnModal.vue';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
// import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

onMounted(() => {
    store.dispatch('parkingOwner/getOwnerList');
});

const isModalOpen = ref(false);
const modalTitle = ref("");
const onConfirm = ref(null);

const openModal = (title, confirmAction) => {
    modalTitle.value = title;
    onConfirm.value = confirmAction;
    isModalOpen.value = true;
};

const isOneBtnModalOpen = ref(false);
const modalText = ref("");
const isAlertModal = ref(false);

const openOneBtnModal = (title, text, alert) => {
    modalTitle.value = title;
    modalText.value = text
    isAlertModal.value = alert
    isOneBtnModalOpen.value = true;
};

const store = useStore();

const currentPage = ref(1);
const perPage = ref(15);
const tableHeaders = reactive([
    { key: 'name', title: '이름', width: '4rem' },
    { key: 'phoneNumber', title: '연락처', width: '4rem' },
    { key: 'affCnt', title: '제휴 주차장 수', width: '4rem' },
    { key: 'affList', title: '제휴 주차장', width: '5rem' },
]);

const ownerList = computed(() => store.getters['parkingOwner/ownerList']);
const filteredList = ref([]);
const selectedOwners = ref([]);
const selectedRow = ref(null);
const affParkingList = ref([]);
const selectedAff = ref(null);


const keywordOptions = [
    { key: 'name', label: '소유자 이름' },
    { key: 'phoneNumber', label: '소유자 연락처' },
    { key: 'parkingName', label: '주차장 이름' },
    { key: 'parkingAddress', label: '주차장 주소' },
];

const searchKeyword = ref({ key: 'name', label: '소유자 이름' });
const search = ref('');

const handleSearchInput = (event) => {
    search.value = event.target.value;
};

watchEffect(() => {
    if (ownerList.value.length === 0) {
        filteredList.value = [];
        return;
    }

    filteredList.value = ownerList.value.filter(item => {
        let keywordMatch = true;
        const formatted = search.value.replace(/\s/g, '')

        if (searchKeyword.value.key == "parkingName") {
            keywordMatch = item.parkingInfoList.some(parking => {
                return parking.name && parking.name.toLowerCase().includes(formatted);
            })
        } else if (searchKeyword.value.key == 'parkingAddress') {
            keywordMatch = item.parkingInfoList.some(parking => {
                return parking.parkingAddress && parking.parkingAddress.toLowerCase().includes(formatted);
            })
        } else {
            keywordMatch = !searchKeyword.value.key || (item[searchKeyword.value.key].includes(formatted));
        }
        return keywordMatch;
    });

});

const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredList.value.slice(start, end);
});

const openDetail = (row) => {
    selectedRow.value = JSON.parse(JSON.stringify(store.getters['parkingOwner/getOwnerById'](row.id)));
    affParkingList.value = selectedRow.value.affSelection
    selectedAff.value = affParkingList.value[0]

    console.log(selectedAff.value)
}

const fileInput = ref(null);
const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0]; // 선택한 파일 저장

    const formData = new FormData();
    formData.append('file', selectedFile); // 'file'은 서버에서 받을 key 값

    try {
        await store.dispatch('parkingOwner/addParkingOwner', formData)
        openOneBtnModal('주차장 소유자가 성공적으로 추가되었습니다.', null);
    } catch (error) {
        openOneBtnModal('주차장 소유자가 등록 중 에러가 발생했습니다.', "등록 데이터를 다시 한번 확인해주세요.", true);
    } finally {
        fileInput.value = null;
        event.target.value = "";
    }
};

const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/templates/소유자 등록 양식.xlsx';
    link.download = '쉽차장_소유자 등록 양식.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const flattenData = (data) => {
    const rows = [];
    data.forEach((item) => {
        console.log("평탄화", item)
        if (item.parkingInfoList && item.parkingInfoList.length > 0) {
            item.parkingInfoList.forEach((subItem) => {
                rows.push({
                    ownerName: item.name,
                    phoneNumber: item.phoneNumber,
                    birthDate: item.birthDate,
                    ownerAddress: subItem.ownerAddress,
                    settlementBank: subItem.settlementBank,
                    settlementAccount: subItem.settlementAccount,
                    contractStartDate: subItem.contractStartDate,
                    contractEndDate: subItem.contractEndDate,
                    asExpireDate: subItem.asExpireDate,
                    parkingName: subItem.name,
                    parkingAddress: subItem.parkingAddress,
                    lat: subItem.lat,
                    lng: subItem.lng,
                    charge: subItem.charge,
                    perMinuteFee: subItem.perMinuteFee,
                    affiliatedSeat: subItem.affiliatedSeat,
                    operatingTime: subItem.operatingTime,
                    closingTime: subItem.closingTime,
                    businessName: subItem.businessName,
                    businessRegistrationNumber: subItem.businessRegistrationNumber
                });
            });
        } else {
            // sublist가 없을 경우, 부모 데이터만 추가
            rows.push({
                ownerName: item.name,
                phoneNumber: item.phoneNumber,
                birthDate: item.birthDate,
            });
        }
    });

    return { rows };
};


const downloadExcel = async () => {
    // ✅ 1. 미리 저장된 엑셀 파일을 불러오기
    const response = await fetch("/templates/parking_owner_template.xlsx"); // `public/` 폴더 내 파일 접근
    const arrayBuffer = await response.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: "array", cellStyles: true });

    // ✅ 2. 첫 번째 시트 선택 (기본적으로 "정산내역" 시트)
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    const excelData = flattenData(filteredList.value).rows
    console.log("평탄화 완료", excelData)
    // ✅ 3. 동적으로 추가할 데이터 준비
    const dataToExport = excelData.map((item, index) => [
        index + 1, // NO
        item.ownerName, // 소유자 이름
        item.birthDate, // 생년월일
        item.phoneNumber, // 연락처
        item.ownerAddress, // 주소
        // 계약정보
        item.settlementBank, // 정산은행
        item.settlementAccount, // 정산계좌
        item.contractStartDate, // 계약일
        item.contractEndDate, // 계약만료일
        item.asExpireDate, // as 만료일
        // 계약주차장 정보
        item.parkingName, // 주차장 명
        item.parkingAddress, // 주차장 주소
        item.lat, // 주차장 위도
        item.lng, // 주차장 경도
        item.charge, // 주차장 수수료
        item.affiliatedSeat, // 공유주차면
        item.perMinuteFee, // 분당요금
        item.operatingTime, // 공유시작 시간
        item.closingTime, // 공유종료 시간
        item.businessName, // 사업자상호명
        item.businessRegistrationNumber // 사업자등록번호
    ]);

    // ✅ 4. 데이터 삽입 (기존 스타일 유지)
    XLSX.utils.sheet_add_aoa(worksheet, dataToExport, {
        origin: "A3",
        skipHeader: true
    });

    // 파일을 엑셀 형식으로 다운로드
    XLSX.writeFile(workbook, `소유자리스트.xlsx`);
};

const isItemSelected = () => {
    console.log(selectedOwners.value)
    if (selectedOwners.value.length === 0) {
        openOneBtnModal("삭제할 항목을 선택해주세요.", "", true)
        return
    }
    openModal('선택하신 소유자를 삭제하시겠습니까?', deleteOwners)
};

const deleteOwners = async () => {
    // 선택된 항목이 없는 경우 경고
    try {
        store.dispatch('parkingOwner/deleteMultipleOwners', selectedOwners.value);
        selectedOwners.value = []; // 선택 항목 초기화
    } catch (error) {
        console.error('삭제 중 오류 발생:', error);
    }
};
</script>


<style>
.divsmall3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.divsmall3>button {
    background-color: white;
    border-radius: 8px;
}

.content-wrapper {
    display: flex;
    gap: 0.625rem;
    margin: 0 6.25rem;
}

.basicTable {
    flex: 2;
}

.detailsInfo {
    flex: 1;
    padding: 1.25rem;
    background-color: #ffffff;
    max-width: 51.25rem;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
    float: right;
}

.close-btn:hover {
    color: red;
}

.clickedInfo {
    padding: 1.9rem;
}

.clickedInput {
    margin-bottom: 1.25rem;
    width: 100%;
    height: 40px;
}

.changeInfo {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 0.625rem;
}
</style>