import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatFullDateTime, formatPhoneNumber, formatPrice } from '@/utils/stringFormatter';

export default {
    namespaced: true,
    state: {
        parkingProcessList: [],
    },

    getters: {
        parkingProcessList: (state) => state.parkingProcessList.sort((a, b) => new Date(b.entryDateTime) - new Date(a.entryDateTime)).map(item => ({
            ...item,
            userPhone: formatPhoneNumber(item.userPhone),
            entryDateTime: formatFullDateTime(item.entryDateTime),
            departureDateTime: formatFullDateTime(item.departureDateTime),
            formattedStatus: {
                PARKING: '주차중',
                COMPLETED: '출차 완료',
                PAYMENT_FAILURE: '결제 실패',
                CANCELED: '전체 취소',
                PARTIAL_CANCELED: '부분 취소'
            }[item.status] || '-',
            formattedFee: formatPrice(item.totalAmount),
            formattedPrice: formatPrice(item.amount),
            formattedPoint: formatPrice(item.pointUsage)
        })),
        getParkingProcessById: (state) => (id) => {
            const item = state.parkingProcessList.find(n => n.id == id);
            return item ? {
                ...item,
                userPhone: formatPhoneNumber(item.userPhone),
                formattedEntryTime: formatFullDateTime(item.entryDateTime),
                formattedDepartTime: formatFullDateTime(item.departureDateTime),
                formattedFee: formatPrice(item.totalAmount),
                formattedPrice: formatPrice(item.amount),
                cancelAmount: formatPrice(item.cancelAmount),
                formattedPoint: `${formatPrice(item.pointUsage)} P`
            } : null; // 찾지 못하면 null 반환
        },
    },

    mutations: {
        SET_PARKING_PROCESS_LIST(state, data) {
            state.parkingProcessList = data;
        },
    },

    actions: {
        //주차내역 리스트 조회
        async getParkingProcessList({ commit }, { parkingId = null, filterBy = "ALL", startDate, endDate } = {}) {
            try {
                const response = await basicInstance.get('/parking/process/history', {
                    params: {
                        parkingId,
                        filterBy,
                        startDate,
                        endDate
                    },
                });
                commit('SET_PARKING_PROCESS_LIST', response.data.list);
            } catch (error) {
                console.error('주차내역 조회 실패:', error);
                throw error;
            }
        },

        async postRefund(_, { parkingProcessId, data }) {
            try {
                await basicInstance.post(
                    '/parking/process/cancel',
                    data,
                    {
                        params: { parkingProcessId }
                    });

            } catch (error) {
                let errorMessage = "알 수 없는 오류가 발생했습니다.";
                console.error('결제 취소 실패:', error.response.data);

                switch (error.response.data.code) {
                    case 30004:
                        errorMessage = "비밀번호가 일치하지 않습니다.";
                        break;
                    case 17001:
                        errorMessage = "해당 주차의 결제 내역을 찾을 수 없습니다.";
                        break;
                    case 17002:
                        errorMessage = "아직 출차하지 않은 내역입니다.";
                        break;
                    case 15014:
                        errorMessage = "결제일로부터 5영업일이 지난 내역입니다.";
                        break;
                    case 20001:
                        errorMessage = "해당 결제 내역의 사용자를 찾을 수 없습니다.";
                        break;
                    default:
                        errorMessage = "서버 오류가 발생했습니다.";
                }
                throw errorMessage;
            }
        }
    }
};
