<template>
    <div>
        <h4 class="listTitle">대시보드</h4>
        <div class="dashWrapper">
            <div class="dashMap" id="map" style="width: 100%; "></div>
    
            <div class="cardWrapper">
                <!-- 전체 카드-->
    
                <!-- 전체 누적 사용자 수 카드 -->
                <div class="dashCard">
                    <div class="topCard">
                        <div>
                            <div class="dashTitle">
                                <h6>전체 누적 사용자 수</h6>
                            </div>
                            <div class="dashNum">
                                <div class="NumDiv">
                                    <h4 style="font-weight: 900;">
                                        {{totalUserCountInfo.totalCount}}
                                        <span style="font-family: NanumSquareNeoExtraBold; font-size: 1.25rem;">명</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img :src="require('@/assets/icons/totalUser.svg')" alt="">
                        </div>
                    </div>
    
                    <div class="dashAnalysis">
                        <div v-if="totalUserCountInfo.comparePreviousDay === 0">
                            <span>- 변동 없음</span>
                            <!-- 0일 때는 "-"만 표시 -->
                        </div>
    
                        <div v-else  style="display: flex;">
                            <img :src="getTrendIcon(totalUserCountInfo.comparePreviousDay)" alt="상태 아이콘">
                            <h6>
                                <span :class="totalUserCountInfo.comparePreviousDay > 0 ? 'positive' : 'negative'">
                                              {{ Math.abs(totalUserCountInfo.comparePreviousDay).toFixed(1) }}%
                                            </span> 전일 대비 {{ totalUserCountInfo.comparePreviousDay > 0 ? '상승' : '하락' }}
                            </h6>
                        </div>
                    </div>
                </div>
    
                <!-- 전체 주차장 수 카드 -->
                <div class="dashCard">
                    <div class="topCard">
                        <div>
                            <div class="dashTitle">
                                <h6>전체 주차장 수</h6>
                            </div>
                            <div class="dashNum">
                                <div class="NumDiv">
                                    <h4 style="font-weight: 900;">
                                        {{ new Intl.NumberFormat('ko-KR').format(totalParkingCountInfo.totalCount) }}
                                        <span style="font-family: NanumSquareNeoExtraBold; font-size: 1.25rem;">곳</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img :src="require('@/assets/icons/totalPaking.svg')" alt="">
                        </div>
                    </div>
    
                    <div class="dashAnalysis">
                        <div v-if="totalParkingCountInfo.comparePreviousDay === 0">
                            <span>- 변동 없음</span>
                            <!-- 0일 때는 "-"만 표시 -->
                        </div>
    
                        <div v-else style="display: flex;">
                            <img :src="getTrendIcon(totalParkingCountInfo.comparePreviousDay)" alt="상태 아이콘">
                            <h6>
                                <span :class="totalParkingCountInfo.comparePreviousDay > 0 ? 'positive' : 'negative'">
                                                      {{ Math.abs(totalParkingCountInfo.comparePreviousDay).toFixed(1) }}%
                                                    </span> 전일 대비 {{ totalParkingCountInfo.comparePreviousDay > 0 ? '상승' : '하락' }}
                            </h6>
                        </div>
                    </div>
                </div>
    
                <!-- 전체 매출 카드 -->
                <div class="dashCard">
                    <div class="topCard">
                        <div>
                            <div class="dashTitle">
                                <h6>전월 매출</h6>
                            </div>
                            <div class="dashNum">
                                <div class="NumDiv">
                                    <h4 style="font-weight: 900;">
                                        {{ new Intl.NumberFormat('ko-KR').format(totalSalesCountInfo.totalCount) }}
                                        <span style="font-family: NanumSquareNeoExtraBold; font-size: 1.25rem;">원</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img :src="require('@/assets/icons/totalSales.svg')" alt="">
                        </div>
                    </div>
    
                    <div class="dashAnalysis">
                        <div v-if="totalSalesCountInfo.comparePreviousMonth === 0">
                            <span>- 변동 없음</span>
                            <!-- 0일 때는 "-"만 표시 -->
                        </div>
    
                        <div v-else style="display: flex;">
                            <img :src="getTrendIcon(totalSalesCountInfo.comparePreviousMonth)" alt="상태 아이콘">
                            <h6>
                                <span :class="totalSalesCountInfo.comparePreviousMonth > 0 ? 'positive' : 'negative'">
                                      {{ Math.abs(totalSalesCountInfo.comparePreviousMonth).toFixed(1) }}%
                                    </span> 전월 대비 {{ totalSalesCountInfo.comparePreviousMonth > 0 ? '상승' : '하락' }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* global naver */
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const totalUserCountInfo = computed(() => store.getters['dashboard/getTotalUserCountInfo']);
const totalParkingCountInfo = computed(() => store.getters['dashboard/getTotalParkingCountInfo']);
const totalSalesCountInfo = computed(() => store.getters['dashboard/getTotalSalesCountInfo']);

onMounted(async () => {
    await store.dispatch('dashboard/getDashboard');
    loadNaverMap();
})


const getTrendIcon = (value) => {
    return value > 0 ?
        require('@/assets/icons/trending-up.svg') :
        require('@/assets/icons/trending-down.svg');
};
const loadNaverMap = () => {
    const script = document.createElement('script');
    script.src = 'https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=c9nk30tsu8';
    script.async = true;
    script.onload = () => {
        var mapOptions = {
            center: new naver.maps.LatLng(35.1595454, 126.8526012),
            zoom: 14
        };
        new naver.maps.Map('map', mapOptions);
    };
    document.head.appendChild(script);
};
</script>

<style>
.dashWrapper {
    margin: 2rem 5rem;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
}

.topCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashMap {
    flex: 4;
}

.dashCard {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    border-radius: 1.125rem;
    background-color: white;
    margin-bottom: 1.9rem;
    width: 260px;
    height: 161px;
}

.dashCard:last-child {
    margin-bottom: 0;
}

.dashTitle {
    font-weight: bold;
    color: #6c757D;
    margin-bottom: 1.25rem;
}

.dashNum {
    margin-bottom: 1.9rem;
}

.dashAnalysis {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    color: #6c757D;
    gap: 0.625rem;
}

.dashAnalysis h6 {
    margin: 0;
}

.negative {
    color: #5b67ec;
}

.positive {
    color: #f93c65;
}
</style>
