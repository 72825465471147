<template>
    <div>
        <h4 class="listTitle">주차장 제보 내역</h4>
        <div class="table-wrapper">
            <div class="header-wrapper">
                <button class="blue-btn notyet-btn" @click="showPending = !showPending" :class="{active: showPending }">미답변보기</button>
    
                <div class="right-header">
                    <div class="btn-wrapper">
                        <DateRangePicker style="margin: 0;" ref="dateRangePicker" :periodOptions="periodOptions" @updateDates="handleDateUpdate" />
                        <button class="blue-btn" @click="getParkingReport">조회</button>
                    </div>
    
                    <div class="btn-wrapper mt-2">
                        <button class="black-btn" v-for="tab in buttons" :key="tab.key" :class="{active: selectedTab === tab.key}" @click="changeType(tab.key)">
                            {{ tab.label }}
                        </button>
                    </div>
    
                </div>
            </div>
    
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="false" @row-clicked="goToDetail" />
    
            <div v-if="paginatedItems.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="reportList.length" align="center" aria-controls="my-table"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

// import Swal from 'sweetalert2';

onMounted(async () => {
    try {
        await store.dispatch('report/getReportList', {type: selectedTab.value, startDate: startDate.value, endDate: endDate.value });
    } catch (error) {
        console.error('공지사항 목록 불러오기 오류:', error);
    }
})

const router = useRouter();
const store = useStore();

const selectedTab = ref("REGISTER"); // 기본 선택값
const showPending = ref(false);
const currentPage = ref(1);
const perPage = ref(15);

const startDate = ref('');
const endDate = ref('');
const periodOptions = reactive([
    { label: "이번달", value: "thisMonth" },
    { label: "3개월", value: "3months" },
    { label: "6개월", value: "6months" },
]);

const handleDateUpdate = ({ startDate: newStart, endDate: newEnd }) => {
    startDate.value = newStart;
    endDate.value = newEnd;
};

const getParkingReport = () => {
    console.log("조회", startDate.value)
    store.dispatch('report/getReportList', {type: selectedTab.value, startDate: startDate.value, endDate: endDate.value });
};

const buttons = ref([
    { label: "신규등록", key: "REGISTER" },
    { label: "요금변경", key: "CHARGE" },
    { label: "폐업", key: "CLOSED" },
    { label: "기타", key: "ETC" },
]);

const changeType = (tabKey) => {
    selectedTab.value = tabKey;
    store.dispatch('report/getReportList', {type: selectedTab.value, startDate: startDate.value, endDate: endDate.value });

    // fetchReport();
};

const tableHeaders = ref([
    { key: 'address', title: '주차장 주소' },
    { key: 'writerName', title: '작성자', width: '6rem' },
    { key: 'formattedCreatedDate', title: '작성 날짜', width: '10rem' },
    { key: 'formattedStatus', title: '답변 상태', width: '8rem' },
    { key: 'formattedUpdateDate', title: '답변 날짜', width: '10rem' },
]);

const reportList = computed(() => store.getters['report/reportList']);

const paginatedItems = computed(() => {
    if (!Array.isArray(reportList.value) || reportList.value.length === 0) {
        return [];
    }

    let filteredItems = reportList.value;
    if (showPending.value) {
        filteredItems = reportList.value.filter((item) => item.status === "PENDING");
    }

    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredItems.slice(start, end);
});

const goToDetail = (item) => {
    if (item.status === "PENDING") {
        router.push({ name: 'tipDetail00', params: { id: item.id } });
    } else {
        router.push({ name: "tipDetail00", params: { id: item.id } });
    }
};

// const fetchReport = () => {
//     try {
//         store.dispatch('report/getReportList', {type: selectedTab.value, startDate: startDate.value, endDate: endDate.value });
//     } catch (error) {
//         // errorMessage.value = "데이터를 불러오는 중 오류가 발생했습니다.";
//     } finally {
//         // loading.value = false;
//     }
// };


// watchEffect(fetchReport);
</script>

