<template>
    <div>
        <h4 class="listTitle">공지사항</h4>
    
        <div class="table-wrapper">
            <div class="btn-wrapper mb-3">
                <button class="black-btn" v-if="!isEditMode" @click="goBack()">←</button>
                <button class="blue-btn" @click="toggleEditMode">{{ isEditMode ? '글 저장' : '글 수정' }}</button>
                <button class="red-btn" v-if="isEditMode" @click="isDeleteModalOpen=true">삭제</button>
                <button class="black-btn" v-if="isEditMode" @click="isCancelModalOpen=true">취소</button>
            </div>
    
            <div>
                <input class="textarea-input" type="text" placeholder="제목" v-model="noticeData.title" :disabled="!isEditMode" :class="{ 'no-cursor': !isEditMode }" />
                <div class="textarea-fixed">
                    <input type="checkbox" v-model="noticeData.isFixed" :disabled="!isEditMode" /><label for="isFixed">상단고정</label>
                </div>
                <div class="textarea-container">
                    <div class="label">본문내용</div>
                    <textarea class="input-area" v-model="noticeData.content" :readonly="!isEditMode"></textarea>
                </div>
            </div>
        </div>
    
        <!-- 수정취소 모달 -->
        <b-modal v-model="isCancelModalOpen" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="modalTitle">공지사항 수정을 취소하시겠습니까?</p>
                    <p class="modalText">변경된 사항은 저장되지 않습니다.</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="goBack()">네</b-button>
                    <b-button class="modal-red-btn" @click="isCancelModalOpen = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
    
        <!-- 삭제 모달 -->
        <b-modal v-model="isDeleteModalOpen" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="car" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="modalTitle">공지사항을 삭제하시겠습니까?</p>
                    <p class="modalText">삭제된 공지사항은 복구되지 않습니다.</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="confirmDelete">네</b-button>
                    <b-button class="modal-red-btn" @click="isDeleteModalOpen = false">아니오</b-button>
                </div>
            </div>
        </b-modal>

        <confirmModal v-model="isModalOpen" :modalTitle="modalTitle" :onConfirm="onConfirm" />

    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import confirmModal from '@/components/confirmModal.vue';
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();
const route = useRoute();

const goBack = () => {
    router.back(); // 브라우저의 뒤로 가기 기능 실행
};

const isCancelModalOpen = ref(false);
const isDeleteModalOpen = ref(false);
const isEditMode = ref(false);
const noticeData = computed(() => JSON.parse(JSON.stringify(store.getters['notice/getNoticeById'](route.params.id))));

watch(() => noticeData.value, (newValue) => {
  if (!newValue) {
    console.warn("🚨 Vuex에 데이터가 없습니다.");
  }
});

const toggleEditMode = () => {
    if (isEditMode.value) {
        openModal('공지사항을 수정하시겠습니까?', confirmSaveChanges)
    } else {
        isEditMode.value = true;
    }
};

const confirmSaveChanges = async () => {
    try {
        await store.dispatch('notice/updateNotice', {
            id: noticeData.value.id,
            updateData: noticeData.value
        });
        isEditMode.value = false;
        router.go(0);
    } catch (error) {
        console.error('공지사항 수정 중 오류 발생:', error);
        Swal.fire({
            text: "공지사항 수정 중 오류가 발생했습니다.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",
        });
    }
};

const confirmDelete = async () => {
    try {
        await store.dispatch('notice/deleteNotice', noticeData.value.id); // 공지사항 삭제
        goBack()
    } catch (error) {
        console.error('공지사항 삭제 중 오류 발생:', error);
    }
};

const isModalOpen = ref(false);
const modalTitle = ref("");
const onConfirm = ref(null);

const openModal = (title, confirmAction) => {
    modalTitle.value = title;
    onConfirm.value = confirmAction;
    isModalOpen.value = true;
};


</script>

<style>
.label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    border-right: 1px solid #e0e0e0;
}

.textarea-container {
    height: 50vh;
    display: flex;
    flex-direction: row;
    border: 1px #E0E0E0 solid;
    border-radius: 1rem;
    background-color: white;
}

.input-area {
    flex: 9;
    outline: none;
    border: none;
    padding: 0.625rem;
    margin: 0.625rem;
    overflow: auto;
    resize: none;
}

.textarea-input {
    width: 100%;
    height: 5vh;
    border: 1px #e0e0e0 solid;
    border-radius: 1rem;
    outline: none;
    padding: 1.25rem;
}

.textarea-input:disabled {
    background-color: white;
}

.textarea-fixed {
    display: flex;
    gap: 0.625rem;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    margin-left: 0.625rem;
}

</style>
