export function formatFullDateTime(dateString) {
  if (!dateString) return '-';
  const date = new Date(dateString);

  const year = String(date.getFullYear()).slice(2); // 3030 → 30
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (01~12)
  const day = String(date.getDate()).padStart(2, '0'); // 일 (01~31)
  const hours = String(date.getHours()).padStart(2, '0'); // 시 (00~23)
  const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 (00~59)

  return `${year}.${month}.${day} ${hours}:${minutes}`;
}

export const formatPhoneNumber = (number) => {
  if (!number) return "";

  number = number.replace(/\D/g, ""); // 숫자 이외의 문자 제거

  if (number.startsWith("02")) {
    // 서울 (02) 지역번호 (2자리 + 3~4자리 + 4자리)
    return number.replace(/^(02)(\d{3,4})(\d{4})$/, "$1-$2-$3");
  } else if (number.startsWith("0")) {
    // 기타 지역번호 (031, 051 등) (3자리 + 3~4자리 + 4자리)
    return number.replace(/^(0\d{2})(\d{3,4})(\d{4})$/, "$1-$2-$3");
  } else if (number.startsWith("1")) {
    // 15XX, 16XX, 18XX 대표번호 (4자리 + 4자리)
    return number.replace(/^(1\d{3})(\d{4})$/, "$1-$2");
  } else {
    return "-"; // 변환 불가능한 경우 그대로 반환
  }
};

export const formatPrice = (value) => {
  if (value === null) {
    return '-'
  }
  return new Intl.NumberFormat('ko-KR').format(value)
}