<template>
    <div>
        <h4 class="listTitle">문의내역</h4>
        <div class="table-wrapper">
            <div class="header-wrapper">
                <div class="btn-wrapper ">
                    <button class="blue-btn" :class="{ active: activeFilter === null }" @click="setFilterStatus(null)">
                                                                                                                  전체보기
                                                                                                                </button>
                    <button class="blue-btn" :class="{ active: activeFilter === 'WAITING' }" @click="setFilterStatus('WAITING')">
                                                                                                                  미답변보기
                                                                                                                </button>
                    <button class="red-btn" :disabled="selectedInquiries.length === 0" @click="isModalOpen = true">
                                                                                                                  삭제
                                                                                                                </button>
    
                </div>
                <div class="btn-wrapper">
                    <DateRangePicker style="margin: 0;" ref="dateRangePicker" :periodOptions="periodOptions" @updateDates="handleDateUpdate" />
                    <button class="blue-btn" @click="getInquiry">조회</button>
                </div>
            </div>
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="true" v-model:selectedRows="selectedInquiries" @row-clicked="goToDetail" />
            <div v-if="paginatedItems.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="inquiryList.length" align="center" aria-controls="my-table"></b-pagination>
            </div>
        </div>
    
        <!-- 삭제 모달 -->
        <b-modal v-model="isModalOpen" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="modalTitle">문의내역을 삭제하시겠습니까?</p>
                    <p class="modalText">삭제된 문의내역은 복구되지 않습니다</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="deleteItem">네</b-button>
                    <b-button class="modal-red-btn" @click="isModalOpen = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';
import Swal from 'sweetalert2';

onMounted(async () => {
    try {
        await store.dispatch('inquiry/getInquiryList', { startDate: startDate.value, endDate: endDate.value });
    } catch (error) {
        console.error('공지사항 목록 불러오기 오류:', error);
    }
})

const router = useRouter();
const store = useStore();

const isModalOpen = ref(false);
const currentPage = ref(1);
const perPage = ref(15);
const filterStatus = ref(null);
const activeFilter = ref(null);

const startDate = ref('');
const endDate = ref('');
const periodOptions = reactive([
    { label: "이번달", value: "thisMonth" },
    { label: "3개월", value: "3months" },
    { label: "6개월", value: "6months" },
]);

const handleDateUpdate = ({ startDate: newStart, endDate: newEnd }) => {
    startDate.value = newStart;
    endDate.value = newEnd;
};

const getInquiry = () => {
    store.dispatch('inquiry/getInquiryList', { startDate: startDate.value, endDate: endDate.value });
};

const tableHeaders = ref([
    { key: 'title', title: '제목' },
    { key: 'writerName', title: '작성자', width: '6rem' },
    { key: 'formattedCreatedDate', title: '작성날짜', width: '10rem' },
    { key: 'formattedStatus', title: '답변여부', width: '10rem' },
    { key: 'formattedUpdateDate', title: '답변 날짜', width: '10rem' },
]);

const selectedInquiries = ref([]);

const inquiryList = computed(() => store.getters['inquiry/inquiryList']);

const paginatedItems = computed(() => {
    if (!Array.isArray(inquiryList.value) || inquiryList.value.length === 0) {
        return [];
    }

    let filteredItems = inquiryList.value;
    if (filterStatus.value === "WAITING") {
        filteredItems = inquiryList.value.filter((item) => item.status === "WAITING");
    }

    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredItems.slice(start, end);
});

const goToDetail = (item) => {
    if (item.status === "WAITING") {
        router.push({ name: 'QandAdetail', params: { id: item.id } });
    } else if (item.status === "COMPLETE") {
        router.push({
            name: "QandAedit",
            params: { id: item.id }
        });
    } else {
        router.push({
            name: "QandA",
            params: { id: item.id },
        });
    }
};

const deleteItem = async () => {
    // 선택된 항목들이 있는지 확인 후 삭제 요청
    if (selectedInquiries.value.length > 0) {
        // 선택된 항목 중 답변 완료 상태가 있는지 확인
        console.log(selectedInquiries.value.some(item => item.status === 'COMPLETE'))

        try {
            // 답변 완료 상태가 없는 경우에만 삭제 진행
            await store.dispatch('inquiry/deleteMultipleInquiries', selectedInquiries.value);
            // 삭제 성공 알림 추가
            Swal.fire({
                imageUrl: require('@/assets/icons/Vector.svg'),
                html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>삭제가 완료되었습니다</div>`,
                confirmButtonText: '확인',
                confirmButtonColor: '#5B67EC',
                imageWidth: 55,
                imageHeight: 55,
            });
        } catch (error) {
            console.error("에러요", error.response.data)
            Swal.fire({
                imageUrl: require('@/assets/icons/Vector.svg'),
                imageWidth: 55,
                imageHeight: 55,
                text: "이미 답변이 등록된 문의내역은 삭제할 수 없습니다..",
                confirmButtonColor: "#5B67EC",
                confirmButtonText: "확인",
            });
        } finally {
            isModalOpen.value = false
        }

    }
};

const setFilterStatus = (status) => {
    filterStatus.value = status;
    activeFilter.value = status;
    currentPage.value = 1;
};
</script>

