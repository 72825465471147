<template>
    <div>
        <h4 class="listTitle">주차장 리스트</h4>
        <div class="table-wrapper">
            <div class="header-wrapper ">
                <div class="left-header">
                    <div class="btn-wrapper mt-2">
                        <input type="file" ref="fileInput" @change="handleFileUpload" accept=".xls,.xlsx" hidden />
                        <button class="blue-btn" @click="triggerFileInput">주차장 등록</button>
                        <button class="blue-btn" @click="downloadTemplate">양식 다운로드</button>
                        <button class="red-btn" @click="confirmDelete">삭제</button>
                    </div>
                </div>
                <div class="right-header">
                    <div class="search-wrapper">
                        <b-dropdown :text="searchKeyword.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item) in keywordOptions" :key="item" @click="searchKeyword = item" class="custom-dropdown">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <div class="search-layout">
                            <input v-model="search" @input="handleSearchInput" type="text" placeholder="검색어를 입력하세요" />
                            <i class="bi bi-search"></i>
                        </div>
                    </div>
                    <div class="btn-wrapper mt-2">
                        <b-dropdown :text="selectedRegion.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item) in regionOptions" :key="item" @click="changeRegion(item)">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
    
                        <b-dropdown :text="selectedCity.label" class="custom-dropdown">
                            <b-dropdown-item v-for="(item, index) in filteredCityOptions" :key="index" @click="selectedCity = item">
                                {{ item.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <button class="black-btn" v-for="tab in affOptions" :key="tab.key" :class="{active: selectedAffType === tab.key}" @click="toggleAffType(tab.key)">
                                    {{ tab.label }}
                                </button>
                    </div>    
                </div>
            </div>
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="true" v-model:selectedRows="selectedParkingLots" @row-clicked="openDetail" />
    
            <div v-if="filteredList.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="filteredList.length" align="center" aria-controls="my-table"></b-pagination>
            </div>
        </div>
    
        <div class="overlay" v-if="selectedRow">
            <div class="backDrop" @click="selectedRow = null" />
            <div class="sidebar-wrapper">
                <button class="close-btn" @click="selectedRow = null">X</button>
    
                <div class="mt-4">
                    <div class="form-group">
                        <label>주차장 이름</label>
                        <input type="text" v-model="selectedRow.name" />
                    </div>
                    <div class="form-group">
                        <label>주차장 주소</label>
                        <input type="text" v-model="selectedRow.address" />
                    </div>
                    <div class="form-group">
                        <label>연락처</label>
                        <input type="text" v-model="selectedRow.contact" />
                    </div>
    
                    <div v-if="!selectedRow.affiliatedSeats">
                        <div class="form-group">
                            <label>운영 시간</label>
                            <div class="fee-layout">
                                <input type="time" v-model="selectedRow.operatingTime" />
                                <p>~</p>
                                <input type="time" v-model="selectedRow.closingTime" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>기본 요금</label>
                            <div class="fee-layout">
                                <input type="number" v-model="selectedRow.firstMinute" />
                                <p>분</p>
                                <input type="number" v-model="selectedRow.firstFee" />
                                <p>원</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>추가 요금</label>
                            <div class="fee-layout">
                                <input type="number" v-model="selectedRow.extraMinute" />
                                <p>분</p>
                                <input type="number" v-model="selectedRow.extraFee" />
                                <p>원</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>당일 최대 요금</label>
                            <input type="number" v-model="selectedRow.limitFeeOfDay" />
                        </div>
                    </div>
                    <div v-else>
                        <div class="form-group">
                            <label>공유 시간</label>
                            <div class="fee-layout">
                                <input type="time" v-model="selectedRow.operatingTime" disabled=true />
                                <p>~</p>
                                <input type="time" v-model="selectedRow.closingTime" disabled=true />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>분당 요금</label>
                            <input type="number" v-model="selectedRow.perMinuteFee" disabled=true />
                        </div>
                        <div class="form-group">
                            <label>가맹 주차면수</label>
                            <input type="number" v-model="selectedRow.affiliatedSeats" disabled=true />
                        </div>
                        <div class="form-group">
                            <label>상호명</label>
                            <input type="text" v-model="selectedRow.businessName" disabled=true />
                        </div>
                        <div class="form-group">
                            <label>수수료</label>
                            <input type="number" v-model="selectedRow.charge" disabled=true />
                        </div>
                        <button class="modal-blue-btn mt-3" @click="isAffPopupOpen = true">가맹점 관리</button>
                    </div>
                    <div class="modalBtn">
                        <button class="modal-blue-btn" @click="editParkingLot">수정</button>
                        <button class="modal-red-btn" @click="openModal('해당 주차장을 삭제하시겠습니까?', deleteParkingLot)">삭제</button>
                    </div>
                </div>
            </div>
        </div>
    
        <b-modal v-model="isAffPopupOpen" hide-header hide-footer centered>
            <p class="modalTitle mt-0">가맹점 관리 </p>
            <div class="modalContainer mt-4">
                <button type="button" class="btn-close" aria-label="Close" @click="isAffPopupOpen=false"></button>
                <div class="sidebar-item-container">
                    <p class="modalText">주차장 이름</p>
                    <p class="modalText">{{ selectedRow?.name || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">계약자</p>
                    <p class="modalText">{{ selectedRow?.businessName || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">사업자 번호</p>
                    <p class="modalText">{{ selectedRow?.businessRegistrationNumber || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">수수료</p>
                    <p class="modalText">{{ selectedRow?.charge || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">공유 시간</p>
                    <p class="modalText">{{ `${selectedRow?.operatingTime} ~ ${selectedRow?.closingTime}` || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">분당 요금</p>
                    <p class="modalText">{{ selectedRow?.perMinuteFee || "-"}}</p>
                </div>
                <div class="sidebar-item-container">
                    <p class="modalText">가맹 주차면수</p>
                    <p class="modalText">{{ selectedRow?.affiliatedSeats || "-"}}</p>
                </div>
                <div class="popup-btnLayout">
                    <div class="modalBtn mt-5">
                        <button class="modal-blue-btn" @click="editAffFee=true">분당요금 변경</button>
                        <button class="modal-blue-btn" @click="editAffSeats=true">가맹 주차면수 변경</button>
                    </div>
                    <button class="modal-blue-btn mt-2" @click="editAffInfo = true">계약 정보 변경</button>
                    <button class="modal-red-btn mt-2" @click="openModal('해당 주차장의 가맹을 해지하시겠습니까?', deleteAff)">가맹 해지</button>
                </div>
            </div>
        </b-modal>
    
        <b-modal v-model="editAffFee" hide-header hide-footer centered>
            <div class="modalContents">
                <div class="modalTextWrapper">
                    <p>변경할 분당 요금을 입력해주세요.</p>
                    <div class="popupInput my-4">
                        <label>분당 요금</label>
                        <input type="number" v-model="affFee" />
                    </div>
                </div>
                <div class="modalBtn">
                    <!-- "네" 버튼 클릭 시 createAnswer 메서드 호출 -->
                    <b-button class="modal-black-btn" @click="editAffFee = false">취소</b-button>
                    <b-button class="modal-blue-btn" @click="postAffFee">변경</b-button>
                </div>
            </div>
        </b-modal>
    
        <b-modal v-model="editAffSeats" hide-header hide-footer centered>
            <div class="modalContents">
                <div class="modalTextWrapper">
                    <p>변경할 가맹 주차면 수를 입력해주세요.</p>
                    <div class="popupInput my-4">
                        <label>가맹 주차면 수</label>
                        <input type="number" v-model="affSeat" />
                    </div>
                </div>
                <div class="modalBtn">
                    <!-- "네" 버튼 클릭 시 createAnswer 메서드 호출 -->
                    <b-button class="modal-black-btn" @click="editAffSeats = false">취소</b-button>
                    <b-button class="modal-blue-btn" @click="postAffSeat">변경</b-button>
                </div>
            </div>
        </b-modal>
    
        <b-modal v-model="editAffInfo" hide-header hide-footer centered>
            <div class="modalContents">
                <div class="modalTextWrapper">
                    <p>변경할 계약 정보를 입력해주세요.</p>
                    <div class="popupInput my-4">
                        <label>공유 시작시간</label>
                        <input type="time" step="600" v-model="affInfo.operatingTime" />
                    </div>
                    <div class="popupInput my-4">
                        <label>공유 종료시간</label>
                        <input type="time" v-model="affInfo.closingTime" />
                    </div>
                    <div class="popupInput my-4">
                        <label>계약자 명</label>
                        <input type="text" v-model="affInfo.businessName" />
                    </div>
                    <div class="popupInput my-4">
                        <label>사업자 번호</label>
                        <input type="number" v-model="affInfo.businessRegistrationNumber" />
                    </div>
                    <div class="popupInput my-4">
                        <label>수수료</label>
                        <input type="number" v-model="affInfo.charge" />
                    </div>
                </div>
                <div class="modalBtn">
                    <!-- "네" 버튼 클릭 시 createAnswer 메서드 호출 -->
                    <b-button class="modal-black-btn" @click="closeEditAff">취소</b-button>
                    <b-button class="modal-blue-btn" @click="postAffInfo">변경</b-button>
                </div>
            </div>
        </b-modal>
    
        <confirmModal v-model="isModalOpen" :modalTitle="modalTitle" :onConfirm="onConfirm" />
        <oneBtnModal v-model="isOneBtnModalOpen" :modalTitle="modalTitle" :modalText="modalText" :alert="isAlertModal" />

    </div>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect, reactive } from 'vue';
import confirmModal from '@/components/confirmModal.vue';
import oneBtnModal from '@/components/oneBtnModal.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import Swal from 'sweetalert2';

const router = useRouter();
const store = useStore();

const currentPage = ref(1);
const perPage = ref(15);
const selectedRow = ref(null);
const tableHeaders = reactive([
    { key: 'region', title: '지역', width: '4rem' },
    { key: 'city', title: '시군구', width: '3rem' },
    { key: 'name', title: '주차장 이름', width: '8rem' },
    { key: 'address', title: '주차장 주소', width: '10rem' },
    { key: 'formattedFirstFee', title: '기본요금', width: '5rem' },
    { key: 'formattedExtraFee', title: '추가요금', width: '5rem' },
    { key: 'affiliatedSeats', title: '가맹 주차면 수', width: '4rem' },
]);

const selectedRegion = ref({ key: null, label: "전국" });
const selectedCity = ref({ key: null, label: "시군구" });
const changeRegion = (item) => {    
    if (selectedRegion.value != item) {
        selectedCity.value = { key: null, label: "시군구" }
    }
    selectedRegion.value = item
}

const keywordOptions = [
    { key: 'name', label: '주차장 이름' },
    { key: 'address', label: '주차장 주소' }
];
const searchKeyword = ref({ key: 'name', label: '주차장 이름' }, );
const search = ref('');

const handleSearchInput = (event) => {
    // 검색어가 한 글자여도 필터가 작동하도록 메서드 호출
    search.value = event.target.value;
};

const affOptions = [
    { label: "가맹", key: 'AFF' },
    { label: "비가맹", key: 'NON-AFF' },
];
const selectedAffType = ref(null);
const toggleAffType = (type) => {
    if (selectedAffType.value === type) {
        selectedAffType.value = null;
    } else {
        selectedAffType.value = type;
    }
}
const parkingList = computed(() => store.getters['parkingLot/formattedParkingLots']);
const regionOptions = computed(() => store.getters['parkingLot/regionOptions']);
const cityOptions = computed(() => store.getters['parkingLot/cityOptions']);
const filteredCityOptions = ref([])
const selectedParkingLots = ref([]);
const filteredList = ref([]);

watchEffect(() => {
    if (!Array.isArray(parkingList.value) || parkingList.value.length === 0) {
        filteredList.value = [];
        return;
    }

    if (selectedRegion.value.key) {
        filteredCityOptions.value = cityOptions.value[selectedRegion.value.label]
    } else {
        filteredCityOptions.value = [{ key: null, label: "시군구" }]
    }

    filteredList.value = parkingList.value.filter(item => {
        const regionMatch = !selectedRegion.value.key || item.region === selectedRegion.value.key;
        const cityMatch = !selectedCity.value.key || item.city === selectedCity.value.key;
        const affMatch = !selectedAffType.value || (selectedAffType.value === 'AFF' ? item.affiliatedSeats : !item.affiliatedSeats);
        const keywordMatch = !searchKeyword.value.key || (item[searchKeyword.value.key].includes(search.value));

        return regionMatch && cityMatch && affMatch && keywordMatch;
    });
});

const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredList.value.slice(start, end);
});

const isModalOpen = ref(false);
const modalTitle = ref("");
const onConfirm = ref(null);

const openModal = (title, confirmAction) => {
    modalTitle.value = title;
    onConfirm.value = confirmAction;
    isModalOpen.value = true;
};

const isOneBtnModalOpen = ref(false);
const modalText = ref("");
const isAlertModal = ref(false);

const openOneBtnModal = (title, text, alert) => {
    modalTitle.value = title;
    modalText.value = text
    isAlertModal.value = alert
    isOneBtnModalOpen.value = true;
};

/* 업로드 파일 양식 다운로드 */ 
const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/templates/주차장 등록 양식.xlsx';
    link.download = '쉽차장-주차장 등록 양식.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/* 주차장 엑셀 업로드 */
const fileInput = ref(null);
const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0]; // 선택한 파일 저장

    const formData = new FormData();
    formData.append('file', selectedFile); // 'file'은 서버에서 받을 key 값

    try {
        await store.dispatch('parkingLot/addParkingItems', formData)
        openOneBtnModal('주차장 데이터가 성공적으로 등록되었습니다.', null);
    } catch (error) {
        openOneBtnModal('주차장 데이터 등록 중 에러가 발생했습니다.', "등록 파일을 다시 한번 확인해주세요.", true);
    } finally {
        fileInput.value = null;
        event.target.value = "";
    }
};

/* 다중 주차장 삭제 */
const confirmDelete = () => {
    if (selectedParkingLots.value.length > 0) {
        let title = `선택한 ${selectedParkingLots.value.length}개의 주차장을 삭제하시겠습니까?`;
        openModal(title, deleteSelectedItems);
    } else {
        // 선택된 항목이 없는 경우 경고
        openOneBtnModal("삭제할 주차장을 선택해주세요.", null, true);
    }
};

const deleteSelectedItems = async () => {
    try {
        await store.dispatch('parkingLot/deleteMultipleParkingLots', selectedParkingLots.value);
        selectedParkingLots.value = []; // 선택 항목 초기화
        openOneBtnModal("성공적으로 삭제되었습니다.", null, false);
    } catch (error) {
        openOneBtnModal("삭제 중 오류가 발생했습니다.", null, true);
    } 
}

/* 주차장 삭제 */
const deleteParkingLot = async () => {
    try {
        await store.dispatch('parkingLot/deleteParkingLot', selectedRow.value.id);
        store.dispatch('parkingLot/getParkingLots');
        selectedRow.value = null;
        openOneBtnModal("성공적으로 삭제되었습니다.", null, false);
    } catch (error) {
        openOneBtnModal("삭제 중 오류가 발생했습니다.", null, true);
    }
};

/* 주차장 정보 수정 */
const editParkingLot = async () => {
    const keysToKeep = [
        'name', 'address', 'seat', 'firstMinute', 'firstFee',
        'extraMinute', 'extraFee', 'limitFeeOfDay', 'contact', 'operatingTime', 'closingTime'
    ]

    const filteredObject = computed(() => Object.fromEntries(
        Object.entries(selectedRow.value)
        .filter(([key]) => keysToKeep.includes(key))
        .map(([key, value]) => [key, value === "" ? null : value]) // 빈 문자열을 null로 변환
    ));

    try {
        await store.dispatch('parkingLot/editParkingLotInfo', { id: selectedRow.value.id, data: filteredObject.value })

    } catch (error) {
        console.error("API 요청 중 오류 발생:", error.response ? error.response.data : error.message);
    } finally {
        console.log("dfd")
    }
};

/* 가맹 해지 */
const isAffPopupOpen = ref(false);
const deleteAff = async () => {
    try {
        await store.dispatch('parkingLot/deleteAffiliates', selectedRow.value.id)
        router.go(0)
    } catch (error) {
        console.error("API 요청 중 오류 발생:", error.response ? error.response.data : error.message);
    }
}

/* 공유 주차면 수정 */
const editAffSeats = ref(false);
const affSeat = ref(null);
const postAffSeat = async () => {
    if (!affSeat.value) {
        Swal.fire({
            text: "가맹 주차면 수를 입력해주세요.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",
        });
        return
    }
    try {
        const data = { 'affiliatedSeat': affSeat.value }
        await store.dispatch('parkingLot/editAffSeat', { id: selectedRow.value.id, data: data })
        selectedRow.value.affiliatedSeats = affSeat.value
        affSeat.value = null
    } catch (error) {
        console.error("API 요청 중 오류 발생:", error.response ? error.response.data : error.message);
    } finally {
        editAffSeats.value = false
    }
};

/* 가맹 요금 변경 */
const editAffFee = ref(false);
const affFee = ref(null)
const postAffFee = async () => {
    if (!affFee.value) {
        Swal.fire({
            text: "분당요금을 입력해주세요.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "확인",
        });
        return
    }
    try {
        const data = { 'perMinuteFee': affFee.value }
        await store.dispatch('parkingLot/editAffFee', { id: selectedRow.value.id, data: data })
        selectedRow.value.perMinuteFee = affFee.value
        affFee.value = null
    } catch (error) {
        console.error("API 요청 중 오류 발생:", error.response ? error.response.data : error.message);
    } finally {
        editAffFee.value = false
    }
};

/* 가맹 정보 변경 */
const editAffInfo = ref(false);
const affInfo = ref({})
const closeEditAff = () => {
    editAffInfo.value = false;
    resetData();
}
const postAffInfo = async () => {
    try {
        await store.dispatch('parkingLot/editAffInfo', { id: selectedRow.value.id, data: affInfo.value })
    } catch (error) {
        console.error("API 요청 중 오류 발생:", error.response ? error.response.data : error.message);
    } finally {
        editAffInfo.value = false
        resetData()
    }
};


const openDetail = (row) => {
    selectedRow.value = JSON.parse(JSON.stringify(store.getters['parkingLot/getParkingLotById'](row.id)));
    affInfo.value = JSON.parse(JSON.stringify(store.getters['parkingLot/getAffInfoById'](row.id)));
}

const resetData = () => {
    selectedRow.value = JSON.parse(JSON.stringify(store.getters['parkingLot/getParkingLotById'](selectedRow.value.id)));
    affInfo.value = JSON.parse(JSON.stringify(store.getters['parkingLot/getAffInfoById'](selectedRow.value.id)));
}

onMounted(() => {
    store.dispatch('parkingLot/getParkingLots');
});
</script>


<style scoped>
.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}

.modalContainer {
    display: flex;
    flex-direction: column;
}

.popup-btnLayout {
    display: flex;
    flex-direction: column;
}
</style>
