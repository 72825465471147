<template>
    <table class="basicTable">
        <thead>
            <tr>
                <th v-if="showCheckbox" style="width: 0.5rem;">
                    <!-- 페이지 항목이 모두 선택된 경우에만 체크 -->
                    <input type="checkbox" @click="toggleAll" :checked="allChecked" />
                </th>
                <th v-for="(header, index) in headers" :key="index" :style="{width: header.width}">
                    {{ header.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="data.length == 0">
                <td :colspan="headers.length + (showCheckbox ? 1 : 0)" class="empty-layout">
                    데이터가 없습니다.
                </td>
            </tr>
            <tr v-for="(row, rowIndex) in data" :key="rowIndex">
                <td v-if="showCheckbox">
                    <input type="checkbox" v-model="selectedRows" :value="row.id" @change="emitSelectedRows" />
                </td>
                <td v-for="(header, colIndex) in headers" :key="colIndex" @click="emitGoToDetail(row)">
                    <img v-if="header.key === 'status'" :src="getStatusIcon(row[header.key])" class="status-icon" />
                    <div v-else>
                        {{ row[header.key] }}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>
  
<script>
import successIcon from '@/assets/icons/icon-payment-success.svg';
import partialCancelIcon from '@/assets/icons/icon-payment-partial-cancel.svg';
import fullCancelIcon from '@/assets/icons/icon-payment-full-cancel.svg';
export default {
    props: {
        headers: Array,
        data: Array,
        showCheckbox: Boolean // 체크박스 추가 여부 (부모에서 전달)
    },
    data() {
        return {
            selectedRows: []
        };
    },
    computed: {
        allChecked: {
            get() {
                return this.selectedRows.length === this.data.length && this.data.length > 0;
            },
            set(value) {
                this.selectedRows = value ? [...this.data.id] : [];
                this.emitSelectedRows(); // 전체 선택 상태 변경 시 부모에게 전달
            }
        }
    },
    methods: {
        toggleAll() {
            if (this.allChecked) {
                this.selectedRows = []; // 전체 해제
            } else {
                console.log(this.data)
                this.selectedRows = this.data.map(item => item.id); // 전체 선택 (id만 저장)
            }
            this.emitSelectedRows();
        },
        emitSelectedRows() {
            this.$emit("update:selectedRows", this.selectedRows); // 부모에게 선택된 행 전달
        },
        emitGoToDetail(data) {
            this.$emit("row-clicked", data);
        },

        getStatusIcon(status) {
            const icons = {
                PAYMENT_DONE: successIcon,
                PARTIAL_CANCELED: partialCancelIcon,
                CANCELED: fullCancelIcon,
            };
            return icons[status] || '/icons/icon-default.svg';
        },
    }
};
</script>
  
<style scoped>
.basicTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #6c757d !important;
    background-color: #ffffff;
    border-radius: 1rem;
    max-height: 100%;
    table-layout: fixed;
}

.basicTable th,
.basicTable td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.5rem 1rem;
}

.basicTable thead th {
    border-bottom: 2px solid #eaeaea;
    font-weight: 400;
}

.basicTable tbody td {
    border-top: 1px solid #eaeaea;
}

.status-icon{
    height: 35px;
}

.empty-layout {
    padding: 2rem !important;
    text-align: center;
}
</style>