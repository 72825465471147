import axios from "axios";
import setInterceptors from "./interceptors";


function createBasicInstance() {

    const instance = axios.create({
        // baseURL: process.env.VUE_APP_BASE_URL_DEV,
        baseURL: 'https://api.simpleparking.co.kr/v1',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    return setInterceptors(instance);
}

export const basicInstance = createBasicInstance();
