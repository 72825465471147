<template>
    <!-- 답변 완료 수정 -->
    <div>
        <h4 class="listTitle">문의내역</h4>
        <div class="table-wrapper ">
            <div class="header-wrapper">
                <div class="btn-wrapper">
                    <button class="black-btn" v-if="!isEditing" @click="goBack">←</button>
                    <button v-if="!isEditing" class="blue-btn" @click="isEditModalOpen = true">답변수정</button>
    
                    <!-- isEditing이 true일 때만 답변저장과 취소 버튼 표시 -->
                    <button v-if="isEditing" class="blue-btn" @click="checkAnswer">답변저장</button>
                    <button v-if="isEditing" class="red-btn" @click="isCancelModalOpen = true">취소</button>
                </div>
            </div>
    
            <!-- 답변 수정 모달 -->
            <b-modal v-model="isEditModalOpen" hide-footer hide-header centered>
                <div class="modalContents">
                    <div class="modalImageWrapper">
                        <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                    </div>
                    <div class="modalTextWrapper">
                        <p class="modalTitle">답변을 수정하시겠습니까?</p>
                    </div>
                    <div class="modalBtn">
                        <b-button class="modal-blue-btn" @click="startEditing">네</b-button>
                        <b-button class="modal-red-btn" @click="isEditModalOpen = false">아니오</b-button>
                    </div>
                </div>
            </b-modal>
    
            <!-- 답변 저장 모달 -->
            <b-modal v-model="isConfirmModalOpen" hide-footer hide-header centered>
                <div class="modalContents">
                    <div class="modalImageWrapper">
                        <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                    </div>
                    <div class="modalTextWrapper">
                        <p class="modalTitle">답변을 저장하시겠습니까?</p>
                    </div>
                    <div class="modalBtn">
                        <b-button class="modal-blue-btn" @click="updateAnswer">네</b-button>
                        <b-button class="modal-red-btn" @click="isConfirmModalOpen = false">아니오</b-button>
                    </div>
                </div>
            </b-modal>
    
            <!-- 답변 삭제 모달 -->
            <b-modal v-model="isCancelModalOpen" hide-footer hide-header centered>
                <div class="modalContents">
                    <div class="modalImageWrapper">
                        <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                    </div>
                    <div class="modalTextWrapper">
                        <p class="modalTitle">답변 수정을 취소하시겠습니까?</p>
                        <p class="modalText">변경된 사항은 저장되지 않습니다</p>
                    </div>
                    <div class="modalBtn">
                        <b-button class="modal-blue-btn" @click="CancelEditing">네</b-button>
                        <b-button class="modal-red-btn" @click="isCancelModalOpen = false">아니오</b-button>
                    </div>
                </div>
            </b-modal>
    
            <table class="qandaTable" align="center">
                <colgroup>
                    <col style="width: 10%;" />
                    <col style="width: 90%;" />
                </colgroup>
                <tr class="title-row">
                    <td>제목</td>
                    <td>{{ inquiryData.title }}</td>
                </tr>
                <tr>
                    <td>작성날짜</td>
                    <td>{{ inquiryData.formattedCreatedDate }}</td>
                </tr>
                <tr>
                    <td>작성자</td>
                    <td>{{ inquiryData.writerName }}</td>
                </tr>
                <tr class="question-row">
                    <td class="large-cell">문의내역</td>
                    <td class="answer-textarea">{{ inquiryData.content }}</td>
                </tr>
                <tr class="answer-row">
                    <td class="large-cell">답변</td>
                    <td>
                        <textarea v-model="answer" :class="{'inactive': !isEditing}" class="answer-textarea"></textarea>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();
const route = useRoute();

const inquiryData = computed(() => store.getters['inquiry/getInquiryById'](route.params.id));
const answer = ref(inquiryData.value.answer);

const isEditing = ref(false);
const isEditModalOpen = ref(false);
const isCancelModalOpen = ref(false);
const isConfirmModalOpen = ref(false);

const goBack = () => {
    router.back(); // 브라우저의 뒤로 가기 기능 실행
};

const startEditing = () => {
    isEditing.value = true;
    isEditModalOpen.value = false;
};

const CancelEditing = () => {
    isCancelModalOpen.value = false;
    isEditing.value = false
    answer.value = inquiryData.value.answer;
}

const checkAnswer = () => {
    if (!answer.value.trim()) {
        // 답변이 비어 있는지 확인
        Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
            imageWidth: 55,
            imageHeight: 55,
            text: "답변 내용을 입력해주세요.",
            confirmButtonColor: "#5B67EC",
            confirmButtonText: "확인",

        });
        return; // answer가 비어 있으면 저장 중단
    } else {
        isConfirmModalOpen.value = true;
    }
}

const updateAnswer = () => {
    try {
        // 서버에 수정된 답변 저장
        store.dispatch('inquiry/updateInquiryAnswer', { id: inquiryData.value.id, answer: answer.value.trim() });
        isEditing.value = false;
        isConfirmModalOpen.value = false;
    } catch (error) {
        console.error('답변 저장 실패:', error.response ? error.response.data : error.message);
    }
};
</script>


<style>
.qandaTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 1.125rem;
    overflow: hidden;
    background-color: white;
    border: 1px solid #6c757d;
}

.qandaTable td:first-child {
    border: 1px solid #eaeaea;
    padding: 0.625rem;
    text-align: center;
    /* 텍스트 가운데 정렬 */
    vertical-align: middle;
    /* 수직 가운데 정렬 */
}

.qandaTable td:last-child {
    border: 1px solid #eaeaea;
    padding-left: 1rem;
}

.qandaTable tr:last-child td:first-child {
    border-bottom-left-radius: 1.125rem;
}

.qandaTable tr:last-child td:last-child {
    border-bottom-right-radius: 1.125rem;
}

.qandaTable tr:first-child td:first-child {
    border-top-left-radius: 1.125rem;
}

.qandaTable tr:first-child td:last-child {
    border-top-right-radius: 1.125rem;
}

.qandaTable .title-row td {
    border-bottom: solid 1px #eaeaea;
}

.qandaTable .answer-row td {
    border-top: double 1px #eaeaea;
    padding: 1rem;
    /* 셀 패딩을 없애서 텍스트 영역이 꽉 차도록 함 */
    height: 25vh;
}

.qandaTable .question-row td:last-child {
    padding: 1rem;
    /* 셀 패딩을 없애서 텍스트 영역이 꽉 차도록 함 */
    vertical-align: top;
    /* 위쪽 정렬 */
    text-align: left;
    /* 텍스트 왼쪽 정렬 */
}

.large-cell {
    height: 25vh !important;
}

.answer-textarea {
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    font-size: 1rem;
    border: none;
    outline: none;
    box-sizing: border-box;
    resize: none;
    background-color: transparent;
    overflow: hidden;
    cursor: text;
}

.answer-textarea.inactive {
    pointer-events: none;
    cursor: default;
    overflow: hidden;
}
</style>
