import { basicInstance } from '@/api/index'; // Axios 인스턴스

export default {
    namespaced: true,
    state: {
        dashBoard: {
            totalUserCountInfo: {},
            totalParkingCountInfo: {},
            totalSalesCountInfo: {}
        },
    },

    getters: {
        getDashBoard: (state) => state.dashBoard,
        getTotalUserCountInfo: (state) => state.dashBoard.totalUserCountInfo, // ✅ 개별 속성 반환
        getTotalParkingCountInfo: (state) => state.dashBoard.totalParkingCountInfo,
        getTotalSalesCountInfo: (state) => state.dashBoard.totalSalesCountInfo
    },

    mutations: {
        SET_DASHBOARD(state, data) {
            state.dashBoard = data;
        },
        CLEAR_PARKINGLOTS(state) {
            state.parkingLots = null;
        },
    },

    actions: {
        async getDashboard({ commit }) {
            try {
                const response = await basicInstance.get('/admin/dashboard');
                commit('SET_DASHBOARD', response.data);
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },
    }
};
