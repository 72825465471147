<template>
    <b-modal v-model="isOpen" hide-footer hide-header centered>
        <div class="modalContents">
            <div class="modalImageWrapper">
              <img v-if="alert" src="@/assets/icons/alert.svg" alt="success" class="modalCar"> 
                <img v-else src="@/assets/icons/Vector.svg" alt="error" class="modalCar">
            </div>
            <div class="modalTextWrapper mt-2">
                <p class="modalTitle">{{ modalTitle }}</p>
                <p v-if="modalText" class="modalText"> {{ modalText}} </p>
            </div>
            <div class="modalBtn">
                <button class="blue-btn" @click="isOpen = false">확인</button>
            </div>
        </div>
    </b-modal>
</template>


<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  modelValue: Boolean, // v-model 바인딩
  modalTitle: {
    type: String,
    default: "완료되었습니다."
  },
  modalText: {
    type: String,
    default: null
  },
  alert:  Boolean,
});

const emit = defineEmits(["update:modelValue"]); // ✅ 명시적으로 이벤트 정의

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

</script>