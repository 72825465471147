<template>
    <!-- 공지사항 새로 작성 -->
    <div>
        <h4 class="listTitle">공지사항</h4>
    
        <div class="table-wrapper">
            <div class="header-wrapper">
                <div class="btn-wrapper">
                    <button class="blue-btn" @click="isRegisterModalOpen = true">등록</button>
                    <button class="black-btn" @click="isCancelModalOpen = true">취소</button>
                </div>
            </div>
    
            <div class="textarea-fixed">

                <label>
                    <input type="radio" v-model="noticeData.auth" value="USER" /> 사용자
                    </label>
                <label>
                    <input type="radio" v-model="noticeData.auth" value="OWNER" /> 소유주
                    </label>


                <input style= "margin-left:2rem" type="checkbox" v-model="noticeData.isFixed" id="isFixed" />
                <label for="isFixed">상단고정</label>
            </div>
            <div>
                <input class="textarea-input" type="text" placeholder="제목" v-model="noticeData.title">
    
                <div class="textarea-container">
                    <div class="label">
                        본문내용
                    </div>
                    <textarea class="input-area" v-model="noticeData.content"></textarea>
                </div>
            </div>
    
            <!-- 등록 모달 -->
            <b-modal v-model="isRegisterModalOpen" hide-footer hide-header centered>
                <div class="modalContents">
                    <div class="modalImageWrapper">
                        <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                    </div>
                    <div class="modalTextWrapper">
                        <p class="modalTitle">공지사항을 등록하시겠습니까?</p>
                    </div>
                    <div class="modalBtn">
                        <b-button class="modal-blue-btn" @click="confirmPostNotice">네</b-button>
                        <b-button class="modal-red-btn" @click="isRegisterModalOpen = false">아니오</b-button>
                    </div>
                </div>
            </b-modal>
    
            <!-- 취소 모달 -->
            <b-modal v-model="isCancelModalOpen" hide-footer hide-header centered>
                <div class="modalContents">
                    <div class="modalImageWrapper">
                        <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                    </div>
                    <div class="modalTextWrapper">
                        <p class="modalTitle">공지사항 등록을 취소하시겠습니까?</p>
                        <p class="modalText">변경된 사항은 저장되지 않습니다.</p>
                    </div>
                    <div class="modalBtn">
                        <b-button class="modal-blue-btn" @click="goBack()">네</b-button>
                        <b-button class="modal-red-btn" @click="isCancelModalOpen = false">아니오</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';

const router = useRouter();
const store = useStore();

const goBack = () => {
    router.back(); // 브라우저의 뒤로 가기 기능 실행
};

// ✅ 반응형 변수 선언
const isRegisterModalOpen = ref(false);
const isCancelModalOpen = ref(false);
const noticeData = ref({
    title: '',
    content: '',
    isFixed: false, // 상단고정 여부
    auth: ''
});

const confirmPostNotice = async () => {
    console.log("전송 데이터:", noticeData); // 전송 전 데이터 확인
    isRegisterModalOpen.value = false; // 모달창 닫기

    // 입력값 유효성 검사
    if (!noticeData.value.title.trim() || !noticeData.value.content.trim()) {
        Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
            imageWidth: 55,
            imageHeight: 55,
            html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:400;'>입력값을 확인하세요</div>
             <div style='font-size:1rem; margin-top: 1rem;'><span style='color:#F93C65;'>제목</span>과 <span style='color:#F93C65;'>본문내용</span>은 필수 입력 값입니다</div>`,
            confirmButtonText: '확인',
            confirmButtonColor: '#5B67EC',
        });
        return; // 빈 값일 경우 함수 종료
    }

    if (!noticeData.value.auth) {
        Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
            imageWidth: 55,
            imageHeight: 55,
            html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:400;'>입력값을 확인하세요</div>
             <div style='font-size:1rem; margin-top: 1rem;'><span style='color:#F93C65;'>공지사항의 대상</span>을 선택해주세요</div>`,
            confirmButtonText: '확인',
            confirmButtonColor: '#5B67EC',
        });
        return; // 빈 값일 경우 함수 종료
    }

    // 유효성 검사를 통과한 경우에만 서버로 데이터 전송
    try {
        await store.dispatch('notice/postNotice', {
            title: noticeData.value.title,
            content: noticeData.value.content,
            isFixed: noticeData.value.isFixed,
            authority: noticeData.value.auth
        });
        console.log("데이터 전송 성공");
        goBack()
    } catch (error) {
        console.error('공지사항 등록 중 오류 발생:', error);
    }

};
</script>

<style>
.textarea-container {
    height: 50vh;
    display: flex;
    flex-direction: row;
    border: 1px #E0E0E0 solid;
    border-radius: 1rem;
    background-color: white;
}

.label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    border-right: 1px solid #E0E0E0;
}

.input-area {
    flex: 9;
    outline: none;
    border: none;
    padding: 0.625rem;
    margin: 0.625rem;
}

.textarea-input {
    width: 100%;
    height: 5vh;
    border: 1px #E0E0E0 solid;
    border-radius: 1rem;
    outline: none;
    padding: 1.25rem;
}

.textarea-fixed {
    display: flex;
    gap: 0.625rem;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    margin-left: 0.625rem;
}

.equal-width-btn {
    flex: 1;
    display: inline-block;
    width: 6.25rem;
    text-align: center;
}
</style>
