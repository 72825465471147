<template>
    <div>
        <h4 class="listTitle">주차 이용 리스트</h4>
        <div class="divLarge">
            <div class="div1">
                <select v-model="reserveSelect1" class="custom-select " style="width: 11.25rem; margin-right: 10px;">
              <option v-for="option in optionsKeyword" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
                <div class="search-wrapper ">
                    <input v-model="search" @input="handleSearchInput" class="search-input" type="text" placeholder="검색어를 입력하세요" />
                    <button @click="emitFilterChange" type="submit" class="search-button">
                <img src="../assets/icons/SearchIcon.svg" alt="Search">
              </button>
                </div>
            </div>
            <div class="div2">
                <div class="divsmall">
                    <button class="auto-width-button blue-btn" @click="downloadExcel">엑셀다운로드</button>
                </div>
                <div class="divsmall2">
                    <button @click="selectButton('PARKING')" :class="{ 'selected': selectedButton === 'PARKING' }">주차중</button>
                    <button @click="selectButton('COMPLETED')" :class="{ 'selected': selectedButton === 'COMPLETED' }">출차완료</button>
                    <button @click="selectButton('FAILURE_PAYMENT')" :class="{ 'selected': selectedButton === 'FAILURE_PAYMENT' }">결제실패</button>
                </div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="table-wrapper">
                <table class="noticeTable" align="center">
                    <thead>
                        <tr>
                            <th style="width: 0%;"></th>
                            <th style="width: 20%;">주차장 이름</th>
                            <th style="width: 10%;">예약자</th>
                            <th style="width: 15%;">연락처</th>
                            <th style="width: 15%;">주차 시작 시간</th>
                            <th style="width: 15%;">주차 종료 시간</th>
                            <th style="width: 10%;">주차상태</th>
                            <th style="width: 7%;">결제여부</th>
                            <th style="width: 8%;">결제금액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in paginatedItems" :key="item.id" @click.stop="handleRowClick(item, index)" :class="{ selected: selectedRowIndex === index }">
                            <td></td>
                            <td>{{ item.parkingName }}</td>
                            <td>{{ item.userName }}</td>
                            <td>{{ formatPhoneNum(item.userPhone) }}</td>
                            <td>{{ formatDate(item.entryDateTime) }}</td>
                            <td>{{ formatDate(item.departureDateTime) }}</td>
                            <td :style="statusColor(item.status)">{{ statusResultLabel(item.status) }}</td>
                            <td>{{ statusPayment(item.status) }}</td>
                            <td>{{ formatNumber(item.amount) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="isDetailsVisible" class="overlay" @click="hideDetails"></div>
            <div v-if="isDetailsVisible" class="detailsInfo" :class="{ open: isDetailsVisible }">
                <button class="close-btn" @click="hideDetails">X</button>
                <table class="parking-process-table">
                    <thead>
                        <tr>
                            <th>주차장 이름</th>
                            <td>
                                {{ clickedData.parkingName }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>예약자</th>
                            <td>{{ clickedData.userName }}</td>
                        </tr>
                        <tr>
                            <th>연락처</th>
                            <td>{{ formatPhoneNum(clickedData.userPhone) }}</td>
                        </tr>
                        <tr>
                            <th>주차 시작 시간</th>
                            <td>{{ formatDate(clickedData.entryDateTime) }}</td>
                        </tr>
                        <tr>
                            <th>주차 종료 시간</th>
                            <td>{{ formatDate(clickedData.departureDateTime) }}</td>
                        </tr>
                        <tr>
                            <th>결제 여부</th>
                            <td>{{ statusPayment(clickedData.status) }}</td>
                        </tr>
                        <tr>
                            <th>결제 금액</th>
                            <td>{{ formatNumber(clickedData.amount) }}</td>
                        </tr>
                        <tr>
                            <th>주차상태</th>
                            <td :style="statusColor(clickedData.status)">{{ statusResultLabel(clickedData.status) }}</td>
                        </tr>
                    </tbody>
                </table>
    
                <div class="btnSection">
                    <button class="blue-btn" style="height: 40px;" @click="isPhotoVisible = true">입차 사진 보기</button>
                </div>
                <!-- 주차장 소유자 관리 삭제버튼 -->
                <div v-if="activePanel === 'clickedInfo3'" class="button-container">
                    <button class="red-btn" @click="모달창열렸니 = true">삭제</button>
                </div>
            </div>
    
    
    
    
    
        </div>
        <div>
            <b-pagination class="tablePage" :per-page="perPage" v-model="currentPage" :total-rows="filteredItems.length" align="center" aria-controls="my-table">
            </b-pagination>
        </div>
        <!-- 주차 사진 확인 팝업 -->
        <div v-if="isPhotoVisible" class="modal-overlay">
            <div class="imagePopup">
                <div class="popupText">
                    <button class="popup-close-btn" @click="isPhotoVisible = false">X</button>
                </div>
                <div class="popupImage">
                    <img v-if="imageUrl" :src="imageUrl" alt="parkingProcessPhoto" class="modal-image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as XLSX from 'xlsx';

export default {
    name: 'parkinglotTip',
    data() {
        return {
            reserveSelect1: null,
            reserveSelect2: null,
            currentPage: 1,
            perPage: 20,
            items: [],
            filteredItems: [],
            selectedButton: null,
            search: '',
            optionsKeyword: [
                { value: null, text: '선택없음', disabled: true },
                { value: 'parkingName', text: '주차장 이름' },
                { value: 'userName', text: '예약자' },

            ],
            reserveOptions2: [
                { value: null, text: '주차장선택', disabled: true },
                { value: 'a', text: '지역1' },
                { value: 'b', text: '지역2' },
                { value: 'c', text: '지역3' },
                { value: 'd', text: '지역4' }
            ],
            clickedData: null,
            isDetailsVisible: false,
            selectedRowIndex: null,
            isPhotoVisible: false,
            imageUrl: null
        };
    },
    computed: {
        ...mapGetters('parkingProcess', ['parkingProcessList']),
        paginatedItems() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.filteredItems.slice(start, end);
        }
    },
    methods: {
        ...mapActions('parkingProcess', ['getParkingProcessList']),
        statusResultLabel(status) {
            const statusMap = {
                PARKING: '주차 중',
                COMPLETED: '출차 완료',
                CANCELED: '예약 취소',
                RESERVED: '예약 완료',
                FAILURE_PAYMENT: '결제실패'
            };
            return statusMap[status] || '-';
        },
        statusPayment(status) {
            const statusMap = {
                PARKING: 'N',
                COMPLETED: "Y",
                PAYMENT_FAILURE: "N"
            };
            return statusMap[status]
        },
        statusColor(status) {
            const colorMap = {
                PARKING: '#5b67ec',
                FAILURE_PAYMENT: '#F93C65',
                CANCELED: '#6c757d'
            };
            return { color: colorMap[status] || 'black' };
        },
        selectButton(filterBy) {
            this.selectedButton = this.selectedButton === filterBy ? null : filterBy;
            this.applyFilters();
        },
        handleSearchInput() {
            this.applyFilters(); // 검색어 입력 시 필터링 적용
        },
        emitFilterChange() {
            this.applyFilters(); // 버튼 클릭 시 필터링 적용
        },
        applyFilters() {
            this.filteredItems = this.parkingProcessList.filter(item => {
                const statusMatch = this.selectedButton ? item.status === this.selectedButton : true;
                const searchMatch = this.search ? item[this.reserveSelect1].includes(this.search) : true;
                const parkingMatch = this.reserveSelect2 ? item.parkingId === this.reserveSelect2 : true;

                return statusMatch && searchMatch && parkingMatch;
            });
        },
        downloadExcel() {
            // 테이블 데이터를 배열 형태로 준비
            const dataToExport = this.filteredItems.map(item => ({
                '주차장 이름': item.parkingName,
                '예약 입차 시간': item.expectedEntryDateTime,
                '예약 출차 시간': item.expectedDepartureDateTime,
                '실제 입차 시간': item.actualEntryDateTime,
                '실제 출차 시간': item.actualDepartureDateTime,
                '예약자': item.userName,
                '기기 ID': item.deviceName,
                '예상 결제': item.expectedPrice,
                '실결제': item.actualPrice,
                '주차상태': this.statusResultLabel(item.status),
            }));

            // 워크북과 시트 생성
            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();

            // 시트를 워크북에 추가
            XLSX.utils.book_append_sheet(workbook, worksheet, "주차장 예약 리스트");

            // 파일을 엑셀 형식으로 다운로드
            XLSX.writeFile(workbook, "주차장_예약_리스트.xlsx");
        },
        formatPhoneNum(value) {
            return value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        },
        formatDate(dateString) {
            if (!dateString) return '-';
            const date = new Date(dateString);

            const year = date.getFullYear().toString().slice(-2); // 연도의 마지막 두 자리 추출
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1) 두 자리로 표시
            const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시

            const hours = String(date.getHours()).padStart(2, '0'); // 시간 두 자리로 표시
            const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 두 자리로 표시

            return `${year}.${month}.${day}\u00A0${hours}:${minutes}`;
        },
        formatNumber(value) {
            if (value == null) return '-';
            return new Intl.NumberFormat().format(value);
        },
        handleRowClick(item, index) {
            this.clickedData = item;
            this.selectedRowIndex = index;
            this.isDetailsVisible = true;
            this.imageUrl = item.imageUrl;
        },
        hideDetails() {
            this.isDetailsVisible = false; // 상세 패널을 숨김
            this.selectedRowIndex = null; // 선택된 행 초기화
        },
    },
    mounted() {
        this.getParkingProcessList();
        this.applyFilters(); // 데이터 로드 후 필터 적용
    },
    watch: {
        paginatedItems(newItems) {
            // 현재 페이지에 데이터가 없으면 첫 페이지로 초기화
            if (newItems.length === 0 && this.currentPage > 1) {
                this.currentPage = 1;
            }
        },
    }
}
</script>

<style>
.container-wrapper {
    display: flex;
    flex-direction: column;
}

.divLarge {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: 6.25rem;
    margin-right: 6.25rem;
}

.div1 {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    width: 30%;
}

.div2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.625rem;
}

.divsmall {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
}

.divsmall2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.divsmall2>button {
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 0.625rem;
    width: 6.25rem;
    height: 40px;
}

.divsmall2>button.selected {
    border-color: #5b67ec;
    color: #5b67ec;
}

.auto-width-button {
    width: auto;
}

.noticeTable {
    width: 100%;
    table-layout: fixed;
}

.noticeTable th,
.noticeTable td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 넘치는 텍스트에 '...' 표시 */
}

.noticeTable tbody tr.selected {
    background-color: #d4d7f5;
    /* 선택된 행의 배경색 */
}

.imagePopup {
    padding: 1rem !important;
    background-color: white !important;
    padding: 2rem;
    border-radius: 12px;
    width: 60%;
    max-width: 500px;
}

.popupImage {
    display: flex;
    justify-content: center;
    /* 가로 정렬 */
    align-items: center;
    /* 세로 정렬 */
    height: 50%;
    /* 이미지 영역 높이 설정 */
}

.popupImage img {
    max-width: 100%;
    max-height: 100%;
}

.parking-process-table {
    margin-top: 3rem;
    width: 100%;
}

.parking-process-table th,
.parking-process-table td {
    padding: 1rem;
}

.parking-process-table thead th {
    border-bottom: 2px solid #eaeaea;
    font-family: NanumSquareNeoBold;
    font-size: smaller;
}

.parking-process-table thead td {
    border-bottom: 2px solid #eaeaea;
    text-align: end;
}

.parking-process-table tbody th {
    border-bottom: 1px solid #eaeaea;
    text-align: start;
    font-family: NanumSquareNeoBold;
    font-size: smaller;
}

.parking-process-table tbody td {
    border-bottom: 1px solid #eaeaea;
    text-align: end;
}
</style>
