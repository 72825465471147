<template>
    <div>
        <h4 class="listTitle">매출통계</h4>
        <div class="table-wrapper">
            <div class="header-wrapper">
                <select v-model="selectedParkingLot" @change="fetchSalesData" class="salesSelect">
                                              <option :value="null">전체 주차장</option>
                                              <option v-for="item in parkingPairList" :key="item.id" :value="item.id">
                                                {{ item.name }}
                                              </option>
                                            </select>
                <DateRangePicker ref="dateRangePicker" :periodOptions="periodOptions" @updateDates="handleDateUpdate" />
                <button class="blue-btn" @click="getParkingSales">조회</button>
                <div style="width: 100%;">
    
                </div>
            </div>
    
            <div class="card-section" v-if="parkingSales">
                <div class="salesCard">
                    <h5>주차 이용 금액</h5>
                    <h6>실제 유저가 사용한 주차 내역</h6>
                    <h3 :style="{ color: '#000000', textAlign: 'right' }">{{ parkingSales.totalAmount }} 원</h3>
                </div>
    
                <div class="salesCard">
                    <h5>취소 금액</h5>
                    <h6>주차요금 중 취소된 내역</h6>
                    <h3 :style="{ color: '#000000', textAlign: 'right' }">{{ parkingSales.cancelAmount }} 원</h3>
                </div>

                <div class="salesCard">
                    <h5>실 이용 금액</h5>
                    <h6>결제 취소된 금액</h6>
                    <h3 :style="{ color: '#000000', textAlign: 'right', fontWeight: 'bold' }">{{ parkingSales.netParkingAmount }} 원</h3>
                </div>
            </div>
    
            <div class="card-section" v-if="parkingSales">
    
                <div class="salesCard">
                    <h5>PG사 수수료</h5>
                    <h6>실 결제건의 {{parkingSales.pgVatRate}}%</h6>
                    <h3 :style="{ color: '#F93C65', textAlign: 'right' }">{{ parkingSales.pgVat }} 원</h3>
                </div>
    
                <div class="salesCard">
                    <h5>소유자 정산금</h5> 
                    <h6 v-if="parkingSales.parkingOwnerVatRate">실 주차 금액의 {{ 100 - parkingSales.parkingOwnerVatRate}}%</h6>
                    <h6 v-else>소유자에게 지급할 금액</h6>
                    <h3 :style="{ color: '#F93C65', textAlign: 'right' }">{{ parkingSales.parkingOwnerVat }} 원</h3>
                </div>
    
                <div class="salesCard">
                    <h5>포인트</h5>
                    <h6>유저가 사용한 포인트 내역</h6>
                    <h3 :style="{ color: '#5B67EC', textAlign: 'right' }">{{ parkingSales.pointUsage }} 원</h3>
                </div>

                <div class="salesCard">
                    <h5>순 이익</h5>
                    <h6>실 매출 내역</h6>
                    <h3 :style="{ color: '#5B67EC', textAlign: 'right' }">{{ parkingSales.totalProfit }} 원</h3>
                </div>
            </div>
    
            <div class="my-2">
                <button class="blue-btn" @click="downloadExcel">매출내역 다운로드</button>
            </div>
    
            <TableComponent :headers="tableHeaders" :data="paginatedItems" :showCheckbox="false" />
    
            <div v-if="salesList.length > 0">
                <b-pagination class="tablePage mt-3" :per-page="perPage" v-model="currentPage" :total-rows="salesList.length" align="center" aria-controls="my-table">
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, reactive, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import TableComponent from '@/components/TableComponent.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';
import * as XLSX from 'xlsx';

const store = useStore();

const startDate = ref('');
const endDate = ref('');
const periodOptions = reactive([
    { label: "오늘", value: "today" },
    { label: "이번달", value: "thisMonth" },
    { label: "1개월", value: "1month" },
    { label: "3개월", value: "3months" },
    { label: "6개월", value: "6months" },
    { label: "1년", value: "1years" },
]);

// 자식 컴포넌트에서 이벤트를 받을 함수
const handleDateUpdate = ({ startDate: newStart, endDate: newEnd }) => {
    startDate.value = newStart;
    endDate.value = newEnd;
};

onBeforeMount(() => {
    store.dispatch('sales/getParkingPair');
    store.dispatch('sales/getParkingSalesById', { parkingId: null, startDate: startDate.value, endDate: endDate.value });
});

const currentPage = ref(1);
const perPage = ref(15);
const tableHeaders = reactive([
    { key: 'parkingName', title: '주차장 이름', width: '5rem' },
    { key: 'formattedPaymentDate', title: '거래일', width: '5rem' },
    { key: 'cardName', title: '카드사', width: '4rem' },
    { key: 'parkingPrice', title: '주차 요금', width: '4rem' },
    { key: 'cancelPrice', title: '취소 금액', width: '4rem' },
    { key: 'pgVat', title: 'PG수수료', width: '4rem' },
    { key: 'parkingOwnerCharge', title: '정산금', width: '4rem' },
    { key: 'pointUsage', title: '포인트', width: '4rem' },
    { key: 'netProfit', title: '순이익', width: '4rem' },
    { key: 'status', title: '결제상태', width: '4rem' }
]);

const selectedParkingLot = ref(null);
const parkingPairList = computed(() => store.getters['sales/ParkingPair']);
const parkingSales = computed(() => store.getters['sales/ParkingSales']);
const salesList = computed(() => store.getters['sales/ParkingSaleList']);

const getParkingSales = () => {
    currentPage.value = 1
    store.dispatch('sales/getParkingSalesById', { parkingId: selectedParkingLot.value, startDate: startDate.value, endDate: endDate.value });
};

const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return salesList.value.slice(start, end);
});

const downloadExcel = async () => {
    // ✅ 1. 미리 저장된 엑셀 파일을 불러오기
    const response = await fetch("/templates/parking_sales_template.xlsx"); // `public/` 폴더 내 파일 접근
    const arrayBuffer = await response.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: "array", cellStyles: true });

    // ✅ 2. 첫 번째 시트 선택 (기본적으로 "정산내역" 시트)
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // ✅ 3. 동적으로 추가할 데이터 준비
    const dataToExport = salesList.value.map((item, index) => [
        index + 1, // NO
        item.formattedPaymentDate, // 거래 일시
        item.cardName, // 카드사
        item.parkingName, // 주차장 이름
        {
            RESERVATION: "예약",
            PASS: "월권",
            PROCESS: "실시간이용",
        }[item.category], // 결제 상태
        item.parkingPrice, // 결제 금액(원)
        item.cancelPrice, // 취소 금액(원)
        item.pgVat, // PG 수수료(원)
        item.parkingOwnerCharge, // 소유주 정산금(원)
        item.pgVat + item.parkingOwnerCharge,
        item.pointUsage,
        item.netProfit,
        {
            PAYMENT_DONE: "결제 완료",
            CANCELED: "전체 취소",
            PARTIAL_CANCELED: "부분 취소",
        }[item.status], // 결제 상태
        item.orderId
    ]);

    // ✅ 4. 데이터 삽입 (기존 스타일 유지)
    XLSX.utils.sheet_add_aoa(worksheet, dataToExport, {
        origin: "A3",
        skipHeader: true
    });

    // 파일을 엑셀 형식으로 다운로드
    XLSX.writeFile(workbook, `정산내역_거래건별_${startDate.value}_${endDate.value}.xlsx`);
};
</script>


<style>
.card-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5rem;
    justify-content: space-between;
    gap: 1rem;
}

.salesCard {
    flex: 1;
    padding: 1rem;
    border: 2px solid #e2e6ea;
    background: #FFFFFF;
    border-radius: 1rem;
}

.salesCard h5 {
    font-size: 18px;
    color: #6C757D;
    font-weight: 600;
}

.salesCard h6 {
    font-size: 14px;
}

.salesCard h3 {
    font-weight: 800;
}

.buttonSection {
    display: flex;
    flex-direction: row;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    justify-content: space-between;
}

.fristbtn {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
}

.fristbtn>button {
    background-color: white;
}

.secondbtn {
    display: flex;
}

.day-btn {
    width: auto;
    height: 40px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border: 1px solid #e2e6ea;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
}

.salesWrapper {
    margin-left: 6.25rem;
    padding-right: 6.25rem;
    margin-top: 50px;
}

.day-input {
    width: auto;
    height: 40px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    border: 1px solid #e2e6ea;
    border-radius: 8px;
    background-color: #f8f9fa;
    cursor: pointer;
}

.selected-button {
    color: #5B67EC;
    border-color: #5B67EC;
}

.salesSelect {
    height: 40px;
    padding: 0.3rem 0.625rem;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
}
</style>