import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatFullDateTime } from '@/utils/stringFormatter';

export default {
    namespaced: true,
    state: {
        noticeList: [],
    },

    getters: {
        noticeList: (state) => state.noticeList.map(item => ({
            ...item,
            isFixed: formatFixed(item.isFixed),
            createdDate: formatFullDateTime(item.createdDate) // 날짜 포맷 적용
        })),
        getNoticeById: (state) => (id) => state.noticeList.find(n => n.id == id) || {}
    },

    mutations: {
        SET_NOTICE_LIST(state, notices) {
            state.noticeList = notices;
        },
    },

    actions: {
        //공지사항 리스트 조회
        async getNoticeList({ commit }) {
            try {
                const response = await basicInstance.get('/notifications');
                commit('SET_NOTICE_LIST', response.data.list);
            } catch (error) {
                console.error('공지사항 조회 실패:', error);
                throw error;
            }
        },


        // 공지사항 작성
        async postNotice({ dispatch }, data) {
            console.log("공지사항 작성내용:", data);
            try {
                await basicInstance.post(`/notifications`, data);
                await dispatch('getNoticeList');
            } catch (error) {
                console.error('공지사항 작성 에러:', error.response ? error.response.data : error.message);
            }
        },

        // 공지사항 삭제
        async deleteNotice({ dispatch }, id) {
            console.log("삭제하려는 공지:", id);
            try {
                await basicInstance.delete(`/notifications/${id}`);
                await dispatch('getNoticeList');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },

        // 공지사항 다중 삭제
        async deleteMultipleNotices({ dispatch }, id) {
            console.log("삭제하려는 다중 공지 :", id);
            try {
                await basicInstance.delete(`/notifications/selected`, {
                    data: {
                        idList: id,
                    },
                });
                await dispatch('getNoticeList');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error; // 오류 발생 시 호출자에게 에러를 던짐
            }
        },

        async updateNotice({dispatch}, { id, updateData }) {
            try {
                await basicInstance.put(`/notifications/${id}`, updateData);
                await dispatch('getNoticeList');
                // 공지사항 수정 후 목록을 다시 가져와 업데이트
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        }

    }
};

function formatFixed(bool) {
    if (bool) {
        return "고정"
    } else {
        return "-"
    }
}