import { basicInstance } from '@/api/index'; // Axios 인스턴스
import { formatPhoneNumber, formatPrice } from '@/utils/stringFormatter';

export default {
    namespaced: true,
    state: {
        parkingLots: [],
    },

    getters: {
        ParkingLots: (state) => state.parkingLots,
        formattedParkingLots: (state) => state.parkingLots.sort((a, b) => new Date(b.id) - new Date(a.id)).map(item => ({
            ...item,
            region: item.address.split(" ")[0],
            city: item.address.split(" ")[1],
            formattedFirstFee: formatFirstFee(item),
            formattedExtraFee: formatExtraFee(item),
        })),
        regionOptions: (state) => {
            const uniqueRegions = new Set(state.parkingLots.map(item => item.address.split(" ")[0]));
            return [{ key: null, label: '전국', disabled:true }, 
                ...[...uniqueRegions].map(region => ({ key: region, label: region }))];    
        },
        cityOptions: (state) => {
            return state.parkingLots.reduce((acc, item) => {
                const [city, district] = item.address.split(" ");
        
                if (!acc[city]) {
                    acc[city] = [{key: null, label: "시군구"}]; // 해당 도시가 없으면 초기화
                }
        
                // 🔹 중복되지 않는 구만 추가
                if (!acc[city].some(option => option.label === district)) {
                    acc[city].push({ key: district, label: district });
                }
                return acc;
            }, {}); 
        },
        
        getParkingLotById: (state) => (id) => {
            const parkingLot = state.parkingLots.find(n => n.id == id);
            return parkingLot ? {
                ...parkingLot,
                contact: formatPhoneNumber(parkingLot.contact)
            } : null; // 찾지 못하면 null 반환
        },
        getAffInfoById: (state) => (id) => {
            const parkingLot = state.parkingLots.find(n => n.id == id);
            return parkingLot ? {
                operatingTime: parkingLot.operatingTime,
                closingTime: parkingLot.closingTime,
                charge: parkingLot.charge,
                businessName: parkingLot.businessName,
                businessRegistrationNumber: parkingLot.businessRegistrationNumber,
            } : null; // 찾지 못하면 null 반환
        },
    },

    mutations: {
        SET_PARKINGLOTS(state, data) {
            state.parkingLots = data;
        },
        CLEAR_PARKINGLOTS(state) {
            state.parkingLots = null;
        },
    },

    actions: {
        async getParkingLots({ commit }) {
            try {
                const response = await basicInstance.get('/parking/admin', {
                    params: {
                        "regionFilter": "ALL",
                        "cityFilter": null
                    }
                });
                commit('SET_PARKINGLOTS', response.data.list);
            } catch (error) {
                console.error('getParkingLots 실패:', error);
            }
        },

        async addParkingItems({ dispatch }, formData) {
            const form = new FormData();
            form.append('registerParkingInfo', formData.get('file')); // formData에 이미 파일이 있는 경우

            try {
                // axios 요청에서 Content-Type은 자동으로 설정되므로 명시적으로 넣지 않음
                await basicInstance.post('/parking', form, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                dispatch('getParkingLots'); // 서버에 저장된 후 주차장 리스트 다시 가져오기

            } catch (error) {
                console.error('Error saving items to the server:', error);
                throw error;
            }
        },

        // 한개씩 삭제
        async deleteParkingLot({ dispatch }, id) {
            console.log("삭제하려는 주차장:", id);
            try {
                await basicInstance.delete(`/parking/${id}`);

                await dispatch('getParkingLots');

            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },

        // 여러개 삭제
        async deleteMultipleParkingLots({ dispatch }, parkingId) {
            console.log("삭제하려는 주차장:", parkingId);
            try {
                await basicInstance.delete(`/parking/selected`, {
                    data: {
                        idList: parkingId,
                    },
                });

                await dispatch('getParkingLots');

            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },

        // 주차장 수정
        async editParkingLotInfo({ dispatch }, { id, data }) {
            try {
                await basicInstance.put(`/parking/${id}`, data);
                await dispatch('getParkingLots');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error; // 오류 발생 시 호출자에게 에러를 던짐

            }
        },

        async deleteAffiliates({ dispatch }, id) {
            try {
                await basicInstance.delete(`/parking/${id}/affiliates/seats`);
                await dispatch('getParkingLots');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error;
            }

        },

        async editAffSeat({ dispatch }, { id, data }) {
            try {
                await basicInstance.put(`/parking/${id}/affiliates/seats`, data);
                await dispatch('getParkingLots');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error;
            }
        },

        async editAffFee({ dispatch }, { id, data }) {
            try {
                await basicInstance.put(`/parking/${id}/fees/per-minute`, data);
                await dispatch('getParkingLots');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error;
            }
        },

        async editAffInfo({ dispatch }, { id, data }) {
            try {
                await basicInstance.put(`/parking/${id}/affiliates`, data);
                await dispatch('getParkingLots');
            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
                throw error;
            }
        }
    }
};

function formatFirstFee(data) {
    if (data.perMinuteFee) {
        return `1분 ${data.perMinuteFee}원`
    }
    if (data.firstMinute == 0 && data.firstFee == 0) {
        return "무료"
    }
    if (!data.firstMinute && !data.firstFee) {
        return "정보없음"
    }
    const formatfee = `${data.firstMinute}분 ${formatPrice(data.firstFee)}원`
    return formatfee
}

function formatExtraFee(data) {
    if (data.perMinuteFee) {
        return "-"
    }
    if (data.extraMinute == 0 && data.extraFee == 0) {
        return "무료"
    }
    if (!data.extraMinute && !data.extraFee) {
        return "정보없음"
    }
    const formatfee = `${data.extraMinute}분 ${formatPrice(data.extraFee)}원`
    return formatfee
}
