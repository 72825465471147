import { basicInstance } from '@/api/index'; // Axios 인스턴스

export default {
    namespaced: true,
    state: {
        tempParkingList: [],
    },

    getters: {
        getTempParking: (state) => state.tempParkingList,
        formattedTempParkingList: (state) => state.tempParkingList.sort((a, b) => b.id - a.id).map(item => ({
            ...item,
            region: item.address.split(" ")[0],
            city: item.address.split(" ")[1],
            startAddress: item.name.split("~")[0],
            endAddress: item.name.split("~")[1],
        })),
        regionOptions: (state) => {
            const uniqueRegions = new Set(state.tempParkingList.map(item => item.address.split(" ")[0]));
            return [{ key: null, label: '지역' }, 
                ...[...uniqueRegions].map(region => ({ key: region, label: region }))];    
        },
        cityOptions: (state) => {
            return state.tempParkingList.reduce((acc, item) => {
                const [city, district] = item.address.split(" ");
        
                if (!acc[city]) {
                    acc[city] = []; // 해당 도시가 없으면 초기화
                }
        
                // 🔹 중복되지 않는 구만 추가
                if (!acc[city].some(option => option.value === district)) {
                    acc[city].push({ key: district, label: district });
                }
                return acc;
            }, {}); 
        },
        getTempParkingById: (state) => (id) => {
            const item = state.tempParkingList.find(n => n.id == id);

            return item ? {
                ...item,
                startAddress: item.name.split("~")[0],
                endAddress: item.name.split("~")[1],
            } : null;
        },
    },

    mutations: {
        SET_TEMP_LIST(state, data) {
            state.tempParkingList = data;
        }
    },

    actions: {
        //한시적 주차 리스트 조회
        async getTempParkingList({ commit }) {
            try {
                const response = await basicInstance.get('/temp-parking-permits', {
                    params: {
                        "regionFilter": "ALL",
                        "cityFilter": null
                    }
                });
                commit('SET_TEMP_LIST', response.data.list);
            } catch (error) {
                console.error('한시적 주차 조회 실패:', error);
                throw error;
            }
        },

        //한시적 주차 리스트 등록
        async addTempParkingList({ dispatch }, formData) {
            const form = new FormData();
            form.append('registerTempParkingPermitInfo', formData.get('file')); // formData에 이미 파일이 있는 경우

            try {
                await basicInstance.post('/temp-parking-permits', form, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                dispatch('getTempParkingList');
            } catch (error) {
                console.error('한시적 주차 조회 실패:', error);
                throw error;
            }
        },

        // 한개씩 삭제
        async deleteTempParking({ dispatch }, id) {
            console.log("삭제하려는 주차장:", id);
            try {
                await basicInstance.delete(`/temp-parking-permits/${id}`);

                await dispatch('getTempParkingList');

            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },

        // 여러개 삭제
        async deleteMultipleTempParking({ dispatch }, parkingId) {
            console.log("삭제하려는 주차장:", parkingId);
            try {
                await basicInstance.delete(`/temp-parking-permits/selected`, {
                    data: {
                        idList: parkingId,
                    },
                });

                await dispatch('getTempParkingList');

            } catch (error) {
                console.error('오류:', error.response ? error.response.data : error.message);
            }
        },


    }
};